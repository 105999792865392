import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Header/Header";
import MainForm from "../../Forms/MainForm";
import OtpBox from "../../Modals/OtpBox";
import { ToastContainer, toast } from "react-toastify";
import { signUpUser, submitOtp } from "../../../api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationModal from "../../Modals/VerificationModal";
import { useSession } from "../../../context/authProvider";
import SignUpModal from "../../Modals/SignUpModal";

const SignUpPage = () => {
  const { setToken, token, userDetails, setUserDetails } = useSession();
  const [open, setOpen] = useState(true);
  const [loginNumber, setLoginNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [openOtpModal, setOpenOtpModal] = useState();
  const [openVerificationModal, setOpenVerificationModal] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => setOpen(false);

  const handleCloseOtpModal = () => {
    setOpenOtpModal(false);
  };

  const handleLoginSubmit = async () => {
    if (loginNumber !== "") {
      const form = {
        phone_number: loginNumber,
      };
      const { status_code, res } = await signUpUser(form);
      if (status_code === 200) {
        // show toaster for success
        setOpenOtpModal(true);
      } else {
        toast.error("res");
        //show for error
      }
    }
  };

  const handleCloseVerificationModal = () => {
    setOpenVerificationModal(false);
  };

  const typeOfUser = useMemo(() => {
    return location.pathname.split("/")[2];
  }, [location.pathname]);

  const handleSubmitOtp = async () => {
    if (otp !== "") {
      const { status_code, res } = await submitOtp(otp);
      if (status_code === 200) {
        sessionStorage.setItem("userDetails", JSON.stringify(res?.data));
        setUserDetails(res?.data?.user);
        setToken(res?.data?.token);
        navigate(`/`);
        handleCloseOtpModal();
        setOpen(false);
      } else {
        toast.error(res);
      }
      handleCloseOtpModal();
    } else {
      toast.error("Please Enter OTP");
    }
  };

  useEffect(() => {
    if (userDetails) {
      setOpen(false);
    }
  }, [userDetails]);

  return (
    <>
      <Header />
      <ToastContainer />
      {/* MainForm */}
      <MainForm
        setOpenOtpModal={setOpenOtpModal}
        openOtpModal={openOtpModal}
        setOpenVerificationModal={setOpenVerificationModal}
      />
      <SignUpModal
        handleLoginSubmit={handleLoginSubmit}
        open={open}
        // handleClose={handleClose}
        // setOpenTwo={setOpenTwo}
        // signUpForm={signUpForm}
        // handleSignUpChange={handleSignUpChange}
        loginNumber={loginNumber}
        // handleSignUpSubmit={handleSignUpSubmit}
        setLoginNumber={setLoginNumber}
      />
      <OtpBox
        openTwo={openOtpModal}
        handleCloseTwo={handleCloseOtpModal}
        otp={otp}
        setOtp={setOtp}
        handleSubmitOtp={handleSubmitOtp}
      />

      <VerificationModal
        open={openVerificationModal}
        onClose={handleCloseVerificationModal}
      />
    </>
  );
};

export default SignUpPage;
