import { Box, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import MuiButton from "../MuiButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
};

const AddManager = ({
  open,
  handleClose,
  handleChange,
  managerEmail,
  handleSubmitManagerForm,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ width: "100%" }}>
            <Box textAlign={"center"}>ADD MANAGER</Box>
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  onChange={handleChange}
                  value={managerEmail}
                  name="email"
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                />
              </Box>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                marginTop: "18px",
              }}
            >
              <span>
                <MuiButton
                  onClick={handleSubmitManagerForm}
                  label={"SEND INVITE"}
                  bgcolor={"#09753a"}
                  color={"#fff"}
                  width="100%"
                  hoverBg={"#09753a"}
                />
              </span>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddManager;
