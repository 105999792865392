import { Divider } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = ({ handleOpenContactUs }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          backgroundColor: "#023718",
          padding: "100px 0px",
          marginTop: "16px",
        }}
      >
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              backgroundColor: "#023718",
            }}
          >
            <h3 style={{ color: "#fff", fontFamily: "mySecondFont" }}>
              Get Your Vehicle Here
            </h3>
            <p style={{ color: "#fff", fontFamily: "mySecondFont" }}>
              We’re a diverse and passionate team that takes ownership of your
              design and empower you to execute the
              <br /> roadmap. We stay light on our feet and truly enjoy
              delivering great work.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={handleOpenContactUs}
                style={{
                  maxWidth: "276px",
                  width: "100%",
                  color: "#fff",
                  backgroundColor: "#09753A",
                  padding: "12px 16px",
                  border: "1px solid #09753A",
                  borderRadius: "8px",
                  fontFamily: "mySecondFont",
                  marginBottom: "90px",
                }}
              >
                CONTACT US{" "}
              </button>
            </div>
          </div>
          <Row>
            <Col md={4}>
              <p style={{ color: "#fff" }}>Bharat Mobility</p>
            </Col>
            <Col md={4}>
              <p style={{ color: "#fff" }}>
                © 2024 Bharat Mobility. All Rights Reserved.{" "}
              </p>
            </Col>
            <Col md={4}>
              {/* <p style={{ color: "#fff" }}>Social Media</p> */}
              <a target="blank" href="https://www.youtube.com/@BharatMobility">
                <YouTubeIcon sx={{ color: "#fff" }} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
