import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import SwiperCSS from "./Swiper.module.css";
import Slider from "react-slick";

const TopSwiper = () => {
  const settings = {
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
  };

  return (
    <Slider {...settings}>
      <img className={SwiperCSS.heroImg} src="/images/hero.png" alt="" />
      <img className={SwiperCSS.heroImg} src="/images/hero2.png" alt="" />
      <img className={SwiperCSS.heroImg} src="/images/hero3.png" alt="" />
    </Slider>
  );
};

export default TopSwiper;
