import { Box, Container, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Header from "../../Header/Header";
import MuiButton from "../../MuiButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { signUpOptions } from "../../../data";
import SellerCard from "./SellerCard";
import Footer from "../../Footer/Footer";
import { useSession } from "../../../context/authProvider";
import VerificationModal from "../../Modals/VerificationModal";
import SignUpModal from "../../Modals/SignUpModal";
import { signUpUser, submitOtp } from "../../../api/Api";
import OtpBox from "../../Modals/OtpBox";
import { toast } from "react-toastify";
import ContactUsModal from "../../Modals/ContactUsModal";

const SellPage = () => {
  const { userDetails, setUserDetails, setToken } = useSession();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLoginModel, setOpenLoginModel] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [loginNumber, setLoginNumber] = useState("");
  const [openVerificationModal, setOpenVerificationModal] = useState();
  const [clickedSeller, setClickedSeller] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenLoginModel = () => setOpenLoginModel(true);
  const handleCloseLoginModel = () => setOpenLoginModel(false);
  const [openContatUs, setContactUs] = useState(false);
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);

  const handleCloseTwo = () => setOpenTwo(false);

  const handleLoginSubmit = async () => {
    if (loginNumber !== "") {
      const form = {
        phone_number: loginNumber,
      };
      const { status_code, res } = await signUpUser(form);
      if (status_code === 200) {
        // show toaster for success
      } else {
        //show for error
      }
      handleCloseLoginModel();
      setOpenTwo(true);
    }
  };

  const handleSubmitOtp = async () => {
    if (otp !== "") {
      const { status_code, res } = await submitOtp(otp);
      if (status_code === 200) {
        //show toaster for success
        sessionStorage.setItem("userDetails", JSON.stringify(res?.data));
        setUserDetails(res?.data?.user);
        setToken(res?.data?.token);
      } else {
        //show for error
        toast.error(res);
      }
      handleCloseTwo();
    }
  };

  const handleCloseVerificationModal = () => {
    setOpenVerificationModal(false);
  };

  const handleClose = (e) => {
    if (e.target.innerText) {
      let routeParam = e.target.innerText.toLowerCase();
      setClickedSeller(routeParam);
      if (
        routeParam === "individual" &&
        userDetails?.isIndividualSeller &&
        userDetails?.isVerified !== 0
      ) {
        setOpenVerificationModal(true);
      } else if (
        routeParam === "dealer" &&
        userDetails?.isDealer &&
        userDetails?.isVerified !== 0
      ) {
        setOpenVerificationModal(true);
      } else if (
        routeParam === "finance" &&
        userDetails?.isFinance &&
        userDetails?.isVerified !== 0
      ) {
        setOpenVerificationModal(true);
      } else if (
        routeParam === "oem" &&
        userDetails?.isOem &&
        userDetails?.isVerified !== 0
      ) {
        setOpenVerificationModal(true);
      } else {
        navigate(`/signUp/${routeParam}`);
      }
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Header handleOpen={handleOpenLoginModel} />
      <SellerCard />
      <div style={{ background: "#fff" }}>
        <Container sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            maxWidth={"460px"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"12px 0px 42px 0px"}
          >
            <MuiButton
              px="16px"
              py="16px"
              width="100%"
              color={"#fff"}
              bgcolor={"#09753A"}
              hoverBg={"#09753A"}
              fontWeight={600}
              label={"SIGN UP AS"}
              Icon={KeyboardArrowDownIcon}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  width: "460px",
                },
              }}
            >
              {signUpOptions.map((opt) => (
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "mySecondFont",
                  }}
                  onClick={handleClose}
                >
                  {opt.title}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Container>
      </div>
      <Footer handleOpenContactUs={handleOpenContactUs} />
      <VerificationModal
        message={`You have already registered as ${clickedSeller} Seller with this Number, but your registration details are still pending verification.`}
        open={openVerificationModal}
        onClose={handleCloseVerificationModal}
      />
      <SignUpModal
        handleLoginSubmit={handleLoginSubmit}
        open={openLoginModel}
        handleClose={handleCloseLoginModel}
        // setOpenTwo={setOpenTwo}
        // signUpForm={signUpForm}
        // handleSignUpChange={handleSignUpChange}
        loginNumber={loginNumber}
        // handleSignUpSubmit={handleSignUpSubmit}
        setLoginNumber={setLoginNumber}
      />
      <OtpBox
        openTwo={openTwo}
        handleCloseTwo={handleCloseTwo}
        otp={otp}
        setOtp={setOtp}
        handleSubmitOtp={handleSubmitOtp}
      />
      <ContactUsModal
        openContatUs={openContatUs}
        handleCloseConatctUs={handleCloseConatctUs}
      />
    </>
  );
};

export default SellPage;
