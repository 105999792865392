import React, { useState } from "react";
import Header from "../../Header/Header";
import { Container } from "react-bootstrap";
import ManagerTable from "./ManagerTable";
import Footer from "../../Footer/Footer";
import ContactUsModal from "../../Modals/ContactUsModal";

const AddManagerPage = () => {
  const [openContatUs, setContactUs] = useState(false);
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);
  return (
    <>
      <Header />
      <ManagerTable />
      <Footer handleOpenContactUs={handleOpenContactUs} />
      <ContactUsModal
        openContatUs={openContatUs}
        handleCloseConatctUs={handleCloseConatctUs}
      />
    </>
  );
};

export default AddManagerPage;
