import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import MuiButton from "../MuiButton";
import { Col } from "react-bootstrap";
import { useSession } from "../../context/authProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "@mui/material/Skeleton";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

const SingleCard = ({
  prod,
  handleClickOpen,
  handleOpen,
  handleOpenBuyNow,
  loader,
  handleCompanyPage,
  setModalLabel,
}) => {
  const { userDetails } = useSession();
  const contactLabel = "CONTACT US";
  const priceLabel = prod?.is_new ? "GET ON ROAD PRICE" : "GET BEST PRICE";
  function openLoginModal(e) {
    handleOpen();
    setModalLabel(e.target.innerText);
  }

  function openBuyNowModal(e) {
    handleOpenBuyNow();
    if (e.target.innerText === contactLabel) {
      setModalLabel(`${contactLabel} on 9818916682 to get more details.`);
    }
    if (e.target.innerText === priceLabel) {
      setModalLabel(`Call us on 9818916682 to get more details.`);
    }
  }
  return (
    <Col md={3}>
      <Box borderRadius={"4px"} border={"1px solid #C6C6C6"} margin={1}>
        <Box
          borderBottom={"1px solid #C6C6C6"}
          display={"flex"}
          justifyContent={"center"}
          height={"40%"}
          sx={{ cursor: "pointer" }}
          // onClick={() => {
          //   navigate(`/product/${prod.id}`);
          // }}
        >
          {loader ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={200}
              animation="wave"
            />
          ) : (
            <Swiper
              centeredSlides={true}
              // slidesPerGroupSkip={null}
              // loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiperTwo"
            >
              {prod?.images?.length > 0 ? (
                prod?.images.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item}
                      alt={`image-${index}`}
                      width={"100%"}
                      height={"200px"}
                      style={{ objectFit: "scale-down" }}
                      onClick={() => {
                        handleClickOpen(prod?._id);
                      }}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <img
                    src={
                      "https://5.imimg.com/data5/SELLER/Default/2022/3/KZ/FM/BK/3511041/piaggio-ape-e-city-3-wheeler-rickshaw-ev.png"
                    }
                    alt="default-image"
                    width={"100%"}
                    height={"200px"}
                    style={{ objectFit: "scale-down" }}
                    onClick={() => {
                      handleClickOpen(prod?._id);
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={"16px"}
          alignItems={"flex-start"}
        >
          {loader ? (
            <Skeleton width={150} animation="wave" variant="text" />
          ) : (
            <Typography
              fontWeight={600}
              fontSize={"24px"}
              color={"#484848"}
              fontFamily={"mySecondFont"}
              sx={{
                textTransform: "capitalize",
                fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem);",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "start",
              }}
            >
              {prod?.name}
            </Typography>
          )}
          {loader ? (
            <Skeleton width={100} animation="wave" variant="text" />
          ) : (
            <Typography
              sx={{
                fontSize: "clamp(1rem, 0.9481rem + 0.2516vw, 1.25rem);",
              }}
              color={"#484848"}
              fontFamily={"mySecondFont"}
            >
              &#8377;{prod?.price}/-{" "}
            </Typography>
          )}
          {loader ? (
            <Skeleton width={80} animation="wave" variant="text" />
          ) : (
            <Typography
              fontSize={"18px"}
              color={"#09753A"}
              fontWeight={400}
              fontFamily={"mySecondFont"}
              textTransform={"capitalize"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleCompanyPage(prod?.company?._id);
              }}
            >
              {prod?.brand}
            </Typography>
          )}
          <Grid container sx={{ py: "4px" }} columnSpacing={"32px"}>
            <Grid item xs={6} sm={6}>
              {loader ? (
                <Skeleton width={50} animation="wave" variant="text" />
              ) : (
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  color={"#7A7A7A"}
                >
                  City
                </Typography>
              )}
              {loader ? (
                <Skeleton width={80} animation="wave" variant="text" />
              ) : (
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                  color={"#242424"}
                >
                  {prod?.city}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              {loader ? (
                <Skeleton width={50} animation="wave" variant="text" />
              ) : (
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"14px"}
                  color={"#7A7A7A"}
                >
                  State
                </Typography>
              )}
              {loader ? (
                <Skeleton width={80} animation="wave" variant="text" />
              ) : (
                <Typography
                  fontFamily={"mySecondFont"}
                  fontSize={"16px"}
                  color={"#242424"}
                >
                  {prod?.state}
                </Typography>
              )}
            </Grid>
            {/* <Grid item xs={4} sm={4}>
              <Typography fontSize={"14px"} color={"#7A7A7A"}>
                Power
              </Typography>
              <Typography fontSize={"16px"} color={"#242424"}>
                {prod?.power}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
          pb={"16px"}
          px={"16px"}
        >
          {loader ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={40}
              animation="wave"
            />
          ) : (
            <MuiButton
              color={"#09753A"}
              width={"100%"}
              bgcolor={"#fff"}
              fontWeight={600}
              hoverBg={"#fff"}
              border={"1px solid #09753A"}
              //   onClick={handleClickOpen}
              label={contactLabel}
              fontFamily={"myFirstFont"}
              onClick={userDetails ? openBuyNowModal : openLoginModal}
            />
          )}
          {loader ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={40}
              animation="wave"
            />
          ) : (
            <MuiButton
              color={"#fff"}
              width={"100%"}
              bgcolor={"#09753A"}
              hoverBg={"#09753A"}
              fontWeight={600}
              //   onClick={handleClick}
              label={priceLabel}
              fontFamily={"myFirstFont"}
              onClick={userDetails ? openBuyNowModal : openLoginModal}
            />
          )}
        </Box>
      </Box>
    </Col>
  );
};

export default SingleCard;
