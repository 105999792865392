import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminTopBarCSS from "./AdminTopBar.module.css";
import MuiButton from "../../MuiButton";
import AddIcon from "@mui/icons-material/Add";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const sort = [
  { label: "Ascending", value: 1 },
  { label: "Descending", value: -1 },
];

const AdminTopBar = ({ handleClick, setPrice, setRange, range, price }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handlePriceSort = (e) => {
    setPrice(e.target.value);
  };
  const handleRangeSort = (e) => {
    setRange(e.target.value);
  };

  return (
    <>
      <Container>
        <div className={AdminTopBarCSS.mainDiv}>
          <div className={AdminTopBarCSS.innerDiv}>
            <Row>
              <Col xs={6} md={3}>
                <MuiButton
                  label="Add Product"
                  Icon={AddIcon}
                  width="100%"
                  bgcolor={"#31824d"}
                  color={"#fff"}
                  py={"12px"}
                  hoverBg={"#31824d"}
                  fontFamily={"mySecondFont"}
                  onClick={() => {
                    navigate(`addproduct`);
                  }}
                />
              </Col>
              <Col xs={6} md={3}>
                <MuiButton
                  onClick={handleClick}
                  label="Add Manager"
                  Icon={AddIcon}
                  width="100%"
                  bgcolor={"#fff"}
                  border="1px solid #31824d"
                  color={"#31824d"}
                  py={"12px"}
                  hoverBg={"#fff"}
                  fontFamily={"mySecondFont"}
                />
              </Col>
              <Col xs={6} md={3} className={AdminTopBarCSS.selectStyle}>
                <Select
                  value={range || "SORT BY RANGE"}
                  sx={{
                    width: "100%",
                    padding: "14px 10px",
                    border: "1px solid #31824d",
                  }}
                  onChange={handleRangeSort}
                >
                  <MenuItem value={"SORT BY RANGE"} disabled>
                    <Box display={"flex"} gap={"16px"}>
                      <Typography fontFamily={"mySecondFont"} fontSize={"14px"}>
                        {"SORT BY RANGE"}
                      </Typography>
                    </Box>
                  </MenuItem>
                  {sort.map((item) => (
                    <MenuItem value={item.value} key={item}>
                      <Box display={"flex"} gap={"16px"}>
                        <Typography
                          fontFamily={"mySecondFont"}
                          fontSize={"14px"}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </Col>
              <Col xs={6} md={3} className={AdminTopBarCSS.selectStyle}>
                <Select
                  //   error={isError2}
                  value={price || "SORT BY PRICE"}
                  sx={{
                    width: "100%",
                    padding: "14px 10px",
                    border: "1px solid #31824d",
                  }}
                  onChange={handlePriceSort}
                >
                  <MenuItem value={"SORT BY PRICE"} disabled>
                    <Box display={"flex"} gap={"16px"}>
                      <Typography fontFamily={"mySecondFont"} fontSize={"14px"}>
                        {"SORT BY PRICE"}
                      </Typography>
                    </Box>
                  </MenuItem>
                  {sort.map((item) => (
                    <MenuItem value={item.value} key={item}>
                      <Box display={"flex"} gap={"16px"}>
                        <Typography
                          fontFamily={"mySecondFont"}
                          fontSize={"14px"}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AdminTopBar;
