import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import { data } from "../../data";
import SingleCard from "./singleCard";
import { useNavigate } from "react-router-dom";
import CardSkeleton from "../Skeleton/CardSkeleton";
import { useSearchProducts } from "../../searchProvider/SearchContext";
// import { publicRoutes } from "../../routes";

const Cards = ({
  handleOpen,
  allProducts,
  handleOpenBuyNow,
  loader = false,
  setModalLabel,
}) => {
  const navigate = useNavigate();

  const { setSearch, setClickSearched } = useSearchProducts();
  const handleClickOpen = (id) => {
    setSearch("");
    setClickSearched(false);
    navigate(`/product/${id}`);
  };
  const handleCompanyPage = (id) => {
    navigate(`/companyPage/${id}`);
  };
  return (
    <>
      <Row>
        {loader ? (
          <>
            {Array.from({ length: 16 }, (_, index) => (
              <CardSkeleton key={index} />
            ))}
          </>
        ) : (
          <>
            {allProducts?.map((prod) => (
              <SingleCard
                key={prod?._id}
                prod={prod}
                loader={loader}
                setModalLabel={setModalLabel}
                handleClickOpen={handleClickOpen}
                handleCompanyPage={handleCompanyPage}
                handleOpen={handleOpen}
                handleOpenBuyNow={handleOpenBuyNow}
              />
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export default Cards;
