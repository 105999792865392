import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Header from "../../Header/Header";
import {
  deleteCompany,
  deleteProduct,
  deleteUser,
  getAllProducts,
  getAllSellers,
  getAllUsers,
  getContactUS,
  updateUser,
} from "../../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import { Box, Container, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSession } from "../../../context/authProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";

const SuperAdminPage = () => {
  const navigate = useNavigate();
  const { token } = useSession();
  const [allUsers, setAllUsers] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalContact, setTotalContact] = useState([]);

  const data = totalContact;

  const downloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const userColumns = [
    {
      field: "_id",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>ID</span>,
      width: 150,
    },
    {
      field: "name",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Name</span>,
      width: 220,
      editable: false,
    },
    {
      field: "email",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Email</span>,
      width: 250,
      editable: false,
    },
    {
      field: "phone_number",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Number</span>,
      width: 220,
      editable: false,
    },

    // {
    //   field: "isDealer",
    //   headerName: "Dealer",
    //   width: 200,
    //   editable: false,
    // },
    // {
    //   field: "isFinance",
    //   headerName: "Finance",
    //   width: 200,
    //   editable: false,
    // },
    // {
    //   field: "isOem",
    //   headerName: "OEM",
    //   width: 200,
    //   editable: false,
    // },
    // {
    //   field: "isIndividualSeller",
    //   headerName: "Individual",
    //   width: 200,
    //   editable: false,
    // },
    {
      field: "status",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Status</span>,
      width: 200,
      editable: false,
      renderCell: (params) => (
        <button
          style={{
            color: "black",
            border: "none",
            borderRadius: "3px",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          {params.row.isManager
            ? "Manger"
            : params.row.isDealer ||
              params.row.isFinance ||
              params.row.isIndividualSeller ||
              params.row.isOem
            ? "Admin"
            : "Buyer"}
        </button>
      ),
    },
    {
      field: "seller",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Seller</span>,
      width: 200,
      editable: false,
      renderCell: (params) => (
        <button
          style={{
            background: "black",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          {params.row.isOem
            ? "oem"
            : params.row.isDealer
            ? "dealer"
            : params.row.isFinance
            ? "finance"
            : params.row.isIndividualSeller
            ? "individual"
            : "buyer"}
        </button>
      ),
    },
    {
      field: "isVerified",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Verification</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <span
          style={{
            cursor: "pointer",
            border: "none",
            borderRadius: "3px",
            fontFamily: "mySecondFont",
            padding: "4px",
            background:
              params.value === 0
                ? "blue"
                : params.value === 1
                ? "green"
                : "#ffdf00",
            color: "white",
          }}
          onClick={() => {
            if (params.value === 2) {
              handleUpdateUser(params.row?.user);
            }
          }}
        >
          {params.value === 0
            ? "Not Verified"
            : params.value === 1
            ? "Verified"
            : "Pending"}
        </span>
      ),
    },
    {
      field: "company_name",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Company</span>,
      width: 220,
      editable: false,
    },
    {
      field: "gst_num",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>GST Number</span>
      ),
      width: 220,
      editable: false,
    },
    {
      field: "website",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Website</span>,
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.website !== "" ? (
            <a target="blank" href={params.row?.website}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "owner_pan_front",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Owner PAN Front</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.owner_pan_front !== "" ? (
            <a target="blank" href={params.row?.owner_pan_front}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "owner_pan_back",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Owner PAN Back</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.owner_pan_back !== "" ? (
            <a target="blank" href={params.row?.owner_pan_back}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "company_pan_front",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Company PAN Front</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.company_pan_front !== "" ? (
            <a target="blank" href={params.row?.company_pan_front}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "company_logo",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Company Logo</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.company_logo !== "" ? (
            <a target="blank" href={params.row?.company_logo}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "company_pan_back",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Company PAN Back</span>
      ),
      width: 220,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row?.company_pan_back !== "" ? (
            <a target="blank" href={params.row?.company_pan_back}>
              click here
            </a>
          ) : (
            "----------------------------"
          )}
        </>
      ),
    },
    {
      field: "delete",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Delete</span>,
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleDeleteCompany(params.row._id)}
          style={{
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          <DeleteIcon />
        </button>
      ),
    },
  ];

  const productColumns = [
    {
      field: "_id",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>ID</span>,
      width: 150,
    },
    {
      field: "name",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Name</span>,
      width: 220,
      editable: false,
    },
    {
      field: "brand",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Brand</span>,
      width: 250,
      editable: false,
    },
    {
      field: "model",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Model</span>,
      width: 220,
      editable: false,
    },
    {
      field: "state",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>State</span>,
      width: 200,
      editable: false,
    },
    {
      field: "pincode",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Pincode</span>,
      width: 200,
      editable: false,
    },
    {
      field: "price",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Price</span>,
      width: 200,
      editable: false,
    },
    {
      field: "registration_number",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Registeration Number</span>
      ),
      width: 200,
      editable: false,
    },
    {
      field: "insurance_expiry",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Insurance</span>,
      width: 200,
      editable: false,
    },
    {
      field: "kms_driven",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>KM Driven</span>,
      width: 200,
      editable: false,
    },
    {
      field: "registered_at",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Registered Date</span>
      ),
      width: 200,
      editable: false,
    },
    {
      field: "number_of_owners",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>owners</span>,
      width: 200,
      editable: false,
    },
    {
      field: "is_new",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>New</span>,
      width: 200,
      editable: false,
    },
    {
      field: "images",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Images</span>,
      width: 200,
      renderCell: (params) => (
        <Swiper
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {params.row.images.map((img) => (
            <SwiperSlide>
              <a target="blank" href={img}>
                <img
                  src={img}
                  style={{
                    width: "80%",
                    height: "100%",
                  }}
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      field: "delete",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Delete</span>,
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleDeleteProduct(params.row._id)}
          style={{
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          <DeleteIcon />
        </button>
      ),
    },
  ];

  const totalUserColumns = [
    {
      field: "_id",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>ID</span>,
      width: 150,
    },
    {
      field: "name",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Name</span>,
      width: 220,
      editable: false,
    },
    {
      field: "email",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Email</span>,
      width: 250,
      editable: false,
    },
    {
      field: "phone_number",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Phone Number</span>
      ),
      width: 220,
      editable: false,
    },
    {
      field: "interested",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Interested Products</span>
      ),
      width: 220,
      renderCell: (params) => (
        <p
          onClick={() => navigate(`/wishList/${params.row._id}`)}
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          click here
        </p>
      ),
    },
    {
      field: "delete",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Delete</span>,
      width: 100,
      renderCell: (params) => (
        <button
          onClick={() => handleDeleteTotalUser(params.row._id)}
          style={{
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          <DeleteIcon />
        </button>
      ),
    },
  ];

  const allContactUs = [
    {
      field: "name",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Name</span>,
      width: 220,
      editable: false,
    },
    {
      field: "email",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Email</span>,
      width: 250,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: (
        <span style={{ fontFamily: "mySecondFont" }}>Phone Number</span>
      ),
      width: 220,
      editable: false,
    },
    {
      field: "message",
      headerName: <span style={{ fontFamily: "mySecondFont" }}>Email</span>,
      width: 250,
      editable: false,
    },
  ];

  const handleUpdateUser = async (user) => {
    user.isVerified = 1;
    const { status_code, res } = await updateUser(user?._id, token, user);
    if (status_code == 200) {
      toast.success(res?.message);
    } else {
      toast.error(res);
    }
  };

  const handleDeleteProduct = async (prodId) => {
    const { status_code, res } = await deleteProduct(prodId, token);
    if (status_code === 200) {
      toast.success(res?.message);
      setAllProducts((prevProds) =>
        prevProds.filter((prod) => prod._id !== prodId)
      );
    } else {
      toast.error(res);
    }
  };

  // deleting the entire company, user will still be there in db
  const handleDeleteCompany = async (userId) => {
    const { status_code, res } = await deleteCompany(userId, token);
    if (status_code === 200) {
      toast.success(res?.message);
      setAllUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== userId)
      );
    } else {
      toast.error(res);
    }
  };

  // delete normal users
  const handleDeleteTotalUser = async (userId) => {
    const { status_code, res } = await deleteUser(userId, token);
    if (status_code === 200) {
      toast.success(res?.message);
      setTotalUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== userId)
      );
    } else {
      toast.error(res);
    }
  };

  const flattenObject = (data) => {
    data.forEach((item) => {
      const userObj = item?.user;
      for (const key in userObj) {
        if (key !== "_id") {
          item[key] = userObj[key];
        } else {
          item["userId"] = userObj[key];
        }
      }
    });
    return data;
  };

  // fetches all companies
  useEffect(() => {
    const fetchAllCompany = async () => {
      const { status_code, res } = await getAllSellers();
      if (status_code === 200) {
        let users = res?.companies || [];
        users = flattenObject(users);
        setAllUsers(users);
      } else {
        toast.error(res);
      }
    };

    fetchAllCompany();
  }, []);

  // fetches all products
  useEffect(() => {
    const fetchAllProducts = async () => {
      const { status_code, res } = await getAllProducts();
      if (status_code === 200) {
        const products = res?.products || [];
        setAllProducts(products);
      } else {
        toast.error(res);
      }
    };
    fetchAllProducts();
  }, []);

  // fetches Contact US
  useEffect(() => {
    const fetchContactUs = async () => {
      const { status_code, res } = await getContactUS();
      if (status_code === 200) {
        const contactUS = res?.data || [];
        setTotalContact(contactUS);
      } else {
        toast.error(res);
      }
    };
    fetchContactUs();
  }, []);

  // fetches all users
  useEffect(() => {
    const fetchAllUsers = async () => {
      const { status_code, res } = await getAllUsers();
      if (status_code === 200) {
        const users = res?.users || [];
        const superAdmin = users.find(
          (user) => user?.phone_number === "918791120538"
        );

        const index = users.findIndex((item) => item._id === superAdmin._id);
        if (index !== -1) {
          users.splice(index, 1);
          users.unshift(superAdmin);
        }
        setTotalUsers(users);
      } else {
        toast.error(res);
      }
    };
    fetchAllUsers();
  }, []);

  const getRowId = (row) => row._id;
  const getRowHeight = (params) => 50;
  return (
    <>
      <Header />
      <ToastContainer />
      {/* Users data grid */}
      <Container>
        <Box sx={{ height: 400, mt: 3 }}>
          <Typography
            textAlign={"start"}
            sx={{
              fontFamily: "mySecondFont",
              fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)",
              color: "#484848",
            }}
          >
            Sellers
          </Typography>
          <DataGrid
            rows={allUsers}
            columns={userColumns}
            getRowId={getRowId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>

        {/* product data grid */}
        <Box sx={{ height: 400, mt: 8 }}>
          <Typography
            textAlign={"start"}
            sx={{
              fontFamily: "mySecondFont",
              fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)",
              color: "#484848",
            }}
          >
            All Products
          </Typography>
          <DataGrid
            rows={allProducts}
            columns={productColumns}
            getRowId={getRowId}
            // getRowHeight={getRowHeight}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>

        {/* totalUser data grid */}
        <Box sx={{ height: 400, mt: 8 }}>
          <Typography
            textAlign={"start"}
            sx={{
              fontFamily: "mySecondFont",
              fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)",
              color: "#484848",
            }}
          >
            All Normal Users
          </Typography>
          <DataGrid
            rows={totalUsers}
            columns={totalUserColumns}
            getRowId={getRowId}
            // getRowHeight={getRowHeight}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>

        <Box sx={{ height: 400, mt: 8 }}>
          <Typography
            textAlign={"start"}
            sx={{
              fontFamily: "mySecondFont",
              fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem)",
              color: "#484848",
            }}
          >
            Contact US
          </Typography>
          <DataGrid
            rows={totalContact}
            columns={allContactUs}
            getRowId={getRowId}
            // getRowHeight={getRowHeight}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        <Box
          sx={{
            marginTop: "70px",
            paddingBottom: "40px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            style={{ fontFamily: "mySecondFont" }}
            onClick={downloadCSV}
            className="btn btn-primary"
          >
            Download CSV
          </button>
        </Box>
      </Container>
    </>
  );
};

export default SuperAdminPage;
