// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Swiper_heroImg__C\\+kw8 {
  width: 100%;
  height: 324px;
  object-fit: scale-down;
}
.Swiper_swiper__HhOTy {
  width: 100%;
  height: 100%;
}
.Swiper_swiper-slide__lZZng {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Swiper_swiper-slide__lZZng img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .Swiper_heroImg__C\\+kw8 {
    height: 200px;
    object-fit: cover;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/swiper/Swiper.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":[".heroImg {\n  width: 100%;\n  height: 324px;\n  object-fit: scale-down;\n}\n.swiper {\n  width: 100%;\n  height: 100%;\n}\n.swiper-slide {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.swiper-slide img {\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n@media screen and (max-width: 992px) {\n  .heroImg {\n    height: 200px;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroImg": `Swiper_heroImg__C+kw8`,
	"swiper": `Swiper_swiper__HhOTy`,
	"swiper-slide": `Swiper_swiper-slide__lZZng`
};
export default ___CSS_LOADER_EXPORT___;
