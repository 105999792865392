import React from "react";
import SellerCardCSS from "./SellerCard.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { typeOfSeller } from "../../../data";

const SellerCard = () => {
  return (
    <>
      <div className={SellerCardCSS.allCards}>
        <Container>
          <Row className={SellerCardCSS.SellerCardDiv}>
            {typeOfSeller.map((item) => {
              return (
                <Col md={3}>
                  <div className={SellerCardCSS.soloCard} key={item.id}>
                    <h3>{item.title}</h3>
                    <p>{item.discription}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SellerCard;
