import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import MuiButton from "../MuiButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "24px",
};

const OtpBox = ({ openTwo, handleCloseTwo, otp, setOtp, handleSubmitOtp }) => {
  return (
    <>
      <Modal
        open={openTwo}
        onClose={handleCloseTwo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ fontFamily: "mySecondFont", color: "#484848" }}>
            Enter Your OTP
          </h2>
          <OtpInput
            inputStyle={{
              width: "4rem",
              height: "4rem",
              fontSize: "clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem)",
              border: "1px solid #ccc",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
          <MuiButton
            onClick={handleSubmitOtp}
            label={"Submit"}
            bgcolor={"#2ea3c5"}
            color={"#fff"}
            width="90%"
            hoverBg={"#2ea3c5"}
          />
        </Box>
      </Modal>
    </>
  );
};

export default OtpBox;
