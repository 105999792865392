import { Box, Grid, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

const Products = ({ prod }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let data = prod;
  return (
    <Container>
      <Box minHeight={"300px"} height={"100%"}>
        <Grid direction={{ xs: "column", md: "row" }} container rowSpacing={1}>
          <Grid item md={4} sm={5} xs={6} bgcolor={"white"}>
            <Box display={"flex"} p={"16px"} gap={"16px"} height={"100%"}>
              <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
                <Box
                  tabIndex={1}
                  width={"72px"}
                  height={"72px"}
                  sx={{ ":focus": { border: "3px solid forestgreen" } }}
                  border={"1px solid #ccc"}
                  borderRadius={"4px"}
                >
                  <img
                    // onClick={(e) => {
                    //   setCurrentimages(e.target.currentSrc);
                    // }}
                    src={data?.images?.[3]}
                    alt=""
                    width={"100%"}
                    style={{
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </Box>

                <Box
                  tabIndex={2}
                  sx={{ ":focus": { border: "3px solid forestgreen" } }}
                  width={"72px"}
                  height={"72px"}
                  border={"1px solid #ccc"}
                  borderRadius={"4px"}
                >
                  <img
                    // onClick={(e) => {
                    //   setCurrentimages(e.target.currentSrc);
                    // }}
                    src={data?.images?.[2]}
                    alt=""
                    width={"100%"}
                    style={{
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </Box>
                <Box
                  tabIndex={3}
                  sx={{ ":focus": { border: "3px solid forestgreen" } }}
                  width={"72px"}
                  height={"72px"}
                  border={"1px solid #ccc"}
                  borderRadius={"4px"}
                >
                  <img
                    // onClick={(e) => {
                    //   setCurrentimages(e.target.currentSrc);
                    // }}
                    src={data?.images?.[1]}
                    alt=""
                    width={"100%"}
                    style={{
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </Box>
                <Box
                  tabIndex={4}
                  sx={{ ":focus": { border: "3px solid forestgreen" } }}
                  width={"72px"}
                  height={"72px"}
                  border={"1px solid #ccc"}
                  borderRadius={"4px"}
                >
                  <img
                    // onClick={(e) => {
                    //   setCurrentimages(e.target.currentSrc);
                    // }}
                    src={data?.images?.[0]}
                    alt=""
                    width={"100%"}
                    style={{
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                border={"1px solid #ccc"}
                // borderRadius={"8px"}
              >
                <img
                  src={data?.images?.[3]}
                  alt=""
                  width={"100%"}
                  style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} sm={5} xs={6} bgcolor={"white"}>
            <Box p={"16px"} display={"flex"} flexDirection={"column"} gap={1}>
              <Grid container>
                <Grid
                  item
                  md={6}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Box mr={2}>
                    <Typography
                      sx={{
                        fontFamily: "mySecondFont !important",
                        marginBottom: "10px !important",
                      }}
                      fontWeight={600}
                      textAlign={"start"}
                    >
                      {data?.name}
                    </Typography>
                    <Typography
                      textAlign={"start"}
                      sx={{ fontFamily: "myThirdFont !important" }}
                    >
                      {data?.description}
                    </Typography>
                  </Box>
                  <Box display={{ xs: "flex", sm: "none" }}>
                    {/* <MoreVertIcon onClick={handleClick} /> */}
                    {/* <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                          fontFamily: "mySecondFont",
                        }}
                      >
                        <EditIcon sx={{ color: "#0080FF" }} /> Edit
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                          fontFamily: "mySecondFont",
                        }}
                      >
                        <DeleteIcon sx={{ color: "#f32013" }} />
                        Delete
                      </MenuItem>
                    </Menu> */}
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  borderLeft={{ xs: "", sm: "1px solid #ccc" }}
                  height={"336px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Box
                    ml={{ xs: 0, sm: 4 }}
                    mt={{ xs: 4, sm: 0 }}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <Typography
                      sx={{
                        fontFamily: "mySecondFont !important",
                        marginBottom: "10px !important",
                      }}
                    >
                      Specification
                    </Typography>
                    <Typography>Brand: {data?.brand}</Typography>
                    <Typography>Model: {data?.model}</Typography>
                    <Typography>Price: ₹{data?.price}</Typography>
                    <Typography>State: {data?.state}</Typography>
                    <Typography>city: {data?.city}</Typography>
                    <Typography>pincode: {data?.pincode}</Typography>
                    <Typography>
                      Number of owners: {data?.number_of_owners}
                    </Typography>
                  </Box>
                  <Box display={{ xs: "none", sm: "flex" }}>
                    {/* <MoreVertIcon onClick={handleClick} />
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                          fontFamily: "mySecondFont",
                        }}
                      >
                        <EditIcon sx={{ color: "#0080FF" }} /> Edit
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        sx={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                          fontFamily: "mySecondFont",
                        }}
                      >
                        <DeleteIcon sx={{ color: "#f32013" }} />
                        Delete
                      </MenuItem>
                    </Menu> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Products;
