import React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
};

const BuyNowModal = ({ openBuyNow, handleCloseBuyNow, label = "" }) => {
  return (
    <>
      <Modal
        open={openBuyNow}
        onClose={handleCloseBuyNow}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src="/images/thankyou.png" alt="" width="100%" />
            </div>
            <p
              style={{
                fontFamily: "mySecondFont",
                fontSize: "clamp(1rem, 0.8962rem + 0.5031vw, 1.5rem);",
                textAlign: "center",
              }}
            >
              {label}
              <br />
            </p>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BuyNowModal;
