import { Avatar, Box, Divider, Grid, Rating, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { details } from "../../../data";
import MuiButton from "../../MuiButton";
import BuyNowModal from "../../Modals/BuyNowModal";
import { useSession } from "../../../context/authProvider";
import SignUpModal from "../../Modals/SignUpModal";
import ProductModal from "../../Modals/ProductModal";

const DetailSection = ({
  productDetails,
  openBuyNow,
  handleOpenBuyNow,
  handleCloseBuyNow,
  open,
  handleQuery,
  handleOpen,
  handleClose,
  handleProductModalClose,
  openProductModal,
  setModalLabel,
}) => {
  const [prodDetails, setProdDetails] = useState({});
  const [currentImage, setCurrentImage] = useState("");
  const [viewMobileNumber, setViewMobileNumber] = useState(false);
  const { userDetails } = useSession();

  useEffect(() => {
    setProdDetails(productDetails);
    const defaultImg =
      productDetails?.images?.[0] ||
      "https://5.imimg.com/data5/SELLER/Default/2022/3/KZ/FM/BK/3511041/piaggio-ape-e-city-3-wheeler-rickshaw-ev.png";
    setCurrentImage(defaultImg);
  }, [productDetails]);

  const showMobileNumber = () => {
    setViewMobileNumber(true);
  };

  function openLoginModal(e) {
    handleOpen();
    setModalLabel(e.target.innerText);
  }

  function openQueryModal(e) {
    handleQuery();
    setModalLabel(e.target.innerText);
  }
  return (
    <>
      <Grid direction={{ xs: "column", md: "row" }} container rowSpacing={1}>
        <Grid item md={4.5} sm={5} xs={6} bgcolor={"white"}>
          <Box display={"flex"} p={"16px"} gap={"16px"} height={"100%"}>
            <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
              {prodDetails.images &&
                prodDetails.images.map((image, index) => (
                  <Box
                    key={index}
                    tabIndex={index + 1}
                    width={"72px"}
                    height={"72px"}
                    sx={{ ":focus": { border: "3px solid forestgreen" } }}
                    border={"1px solid #09753A"}
                    borderRadius={"4px"}
                  >
                    <img
                      onClick={() => {
                        setCurrentImage(image);
                      }}
                      src={image}
                      alt=""
                      width={"100%"}
                      style={{
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "100%",
                        objectFit: "scale-down",
                      }}
                    />
                  </Box>
                ))}
            </Box>

            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              border={"1px solid #dee2e6"}
            >
              {prodDetails.images && prodDetails.images.length > 0 && (
                <img
                  src={currentImage}
                  alt=""
                  width={"100%"}
                  style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "100%",
                    objectFit: "scale-down",
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={4.5} sm={5} xs={6} bgcolor={"white"}>
          <Box p={"16px"} display={"flex"} flexDirection={"column"} gap={1}>
            <Typography
              textAlign={"start"}
              fontWeight={600}
              fontSize={"24px"}
              color={"#484848"}
              fontFamily={"mySecondFont"}
            >
              {prodDetails.name}
            </Typography>
            <Typography
              fontWeight={700}
              fontSize={"24px"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              color={"#2A2A2A"}
              fontFamily={"mySecondFont"}
            >
              &#8377;{prodDetails.price}/-{" "}
              <Typography
                onClick={userDetails ? openQueryModal : openLoginModal}
                color={"#09753A"}
                sx={{
                  fontFamily: "mySecondFont",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Get Latest Prices
              </Typography>
            </Typography>
            <Atrribute
              // gap={"60%"}
              heading={
                <span style={{ fontFamily: "mySecondFont" }}>Company</span>
              }
              text={
                <span style={{ fontFamily: "mySecondFont", color: "#484848" }}>
                  {prodDetails.brand}
                </span>
              }
            />
            <Atrribute
              // gap={"40%"}
              heading={
                <span style={{ fontFamily: "mySecondFont" }}>Model</span>
              }
              text={
                <span style={{ fontFamily: "mySecondFont", color: "#484848" }}>
                  {prodDetails.model}
                </span>
              }
            />
            <Atrribute
              // gap={"75%"}
              heading={<span style={{ fontFamily: "mySecondFont" }}>City</span>}
              text={
                <span style={{ fontFamily: "mySecondFont", color: "#484848" }}>
                  {prodDetails.city}
                </span>
              }
            />
            <Atrribute
              // gap={"74%"}
              heading={
                <span style={{ fontFamily: "mySecondFont" }}>State</span>
              }
              text={
                <span style={{ fontFamily: "mySecondFont", color: "#484848" }}>
                  {prodDetails.state}
                </span>
              }
            />
            <Typography textAlign={"start"} fontFamily={"mySecondFont"}>
              Description:
            </Typography>
            <Typography textAlign={"start"} fontFamily={"mySecondFont"}>
              {prodDetails?.description?.length > 80
                ? prodDetails?.description.slice(0, 80) + "..."
                : prodDetails?.description}
            </Typography>
            <Typography
              textAlign={"start"}
              color={"#09753A"}
              onClick={userDetails ? openQueryModal : openLoginModal}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                fontFamily: "mySecondFont",
              }}
            >
              View complete details
            </Typography>
          </Box>

          <MuiButton
            width={"276px"}
            mt={"12px"}
            px={"16px"}
            py={"12px"}
            color={"#fff"}
            bgcolor={"#09753a"}
            fontWeight={600}
            hoverBg={"#09751e"}
            label={"Buy Now"}
            onClick={userDetails ? openQueryModal : openLoginModal}
          />
        </Grid>
        <Grid item md={3} sm={2} xs={0} bgcolor={"white"}>
          <Box p={"16px"}>
            <Typography
              textAlign={"start"}
              color={"#2A2A2A"}
              fontSize={"18px"}
              fontWeight={600}
              sx={{ textDecoration: "underline" }}
            >
              {prodDetails?.brand}
            </Typography>

            <Grid container columnSpacing={"8px"} mt={"8px"}>
              <Grid item md={6} sm={6} xs={6}>
                <Box width={"100%"} height={"80px"} bgcolor={"#D9D9D9"}></Box>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <Typography color={"#7A7A7A"} fontSize={"12px"}>
                  Sector 32, Gurgaon
                </Typography>
                <p
                  style={{
                    color: "#7A7A7A",
                    fontSize: "12px",
                    marginTop: "8px",
                  }}
                >
                  3.5/5{" "}
                  <span>
                    {" "}
                    <Rating sx={{ fontSize: "16px" }} />
                  </span>
                </p>
                <Typography color={"#7A7A7A"} fontSize={"12px"} mt={"8px"}>
                  GST- 0545545255
                </Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={"12px"} mt={"8px"}>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <Avatar />
                  <Typography>Verified Seller</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <Avatar />
                  <Typography>Verified Seller</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <Avatar />
                  <Typography>Verified Seller</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={"24px"}
              gap={"8px"}
            >
              <MuiButton
                px={"16px"}
                py={"12px"}
                width={"100%"}
                color={"#09753A"}
                bgcolor={"#E8F1EA"}
                fontWeight={600}
                border={"none"}
                label={viewMobileNumber ? "9354645233" : "View Mobile Number"}
                onClick={userDetails ? showMobileNumber : openLoginModal}
              />
              <MuiButton
                px={"16px"}
                py={"12px"}
                width={"100%"}
                color={"#09753A"}
                bgcolor={"#fff"}
                //   hoverBg={"#09753A"}
                fontWeight={600}
                border={"1px solid #09753A"}
                //   onClick={handleClick}
                label={"Contact Supplier"}
                onClick={userDetails ? openQueryModal : openLoginModal}
              />
              <Typography
                textAlign={"start"}
                color={"#09753A"}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={userDetails ? openQueryModal : openLoginModal}
              >
                View more sellers
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SignUpModal handleClose={handleClose} open={open} />
      <ProductModal
        handleClose={handleProductModalClose}
        open={openProductModal}
      />
      <BuyNowModal
        openBuyNow={openBuyNow}
        handleCloseBuyNow={handleCloseBuyNow}
      />
    </>
  );
};

export default DetailSection;

const Atrribute = ({ gap, text, heading }) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        gap={gap}
        color={"secondary.lighter"}
      >
        <Typography>{heading}</Typography>
        <Typography>{text}</Typography>
      </Box>
      <Divider />
    </>
  );
};
