import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import HomeCSS from "./Home.module.css";
import TopSwiper from "../../swiper/TopSwiper";
import Cards from "../../ProductCard/Cards";
import SignUpModal from "../../Modals/SignUpModal";
import OtpBox from "../../Modals/OtpBox";
import {
  getAllProducts,
  signUpUser,
  submitOtp,
  getAllCompany,
} from "../../../api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import BuyNowModal from "../../Modals/BuyNowModal";
import Footer from "../../Footer/Footer";
import { useSession } from "../../../context/authProvider";
import { Search } from "@mui/icons-material";
import { useSearchProducts } from "../../../searchProvider/SearchContext";
import ContactUsModal from "../../Modals/ContactUsModal";

const Home = () => {
  const { setUserDetails, setToken } = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [oldProducts, setOldProducts] = useState([]);
  const [modalLabel, setModalLabel] = useState("");
  const [brands, setBrands] = useState([]);

  const { searchedProducts, searchLoader, clickSearched } = useSearchProducts();
  const [value, setValue] = useState(0);
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    phone_number: "",
  });
  const [loginNumber, setLoginNumber] = useState("");

  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const [openContatUs, setContactUs] = useState(false);
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);
  const handleOpen = () => setOpen(true);
  const handleOpenBuyNow = () => setOpenBuyNow(true);
  const handleClose = () => setOpen(false);
  const handleCloseTwo = () => setOpenTwo(false);
  const handleCloseBuyNow = () => setOpenBuyNow(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBrandClick = (id) => {
    navigate(`/companyPage/${id}`);
  };

  // login details submit
  const handleLoginSubmit = async () => {
    if (loginNumber !== "") {
      const form = {
        phone_number: loginNumber,
      };
      const { status_code, res } = await signUpUser(form);
      if (status_code === 200) {
        // show toaster for success
      } else {
        //show for error
      }
      handleClose();
      setOpenTwo(true);
    }
  };

  const handleSubmitOtp = async () => {
    if (otp !== "") {
      const { status_code, res } = await submitOtp(otp);
      if (status_code === 200) {
        //show toaster for success
        sessionStorage.setItem("userDetails", JSON.stringify(res?.data));
        setUserDetails(res?.data?.user);
        setToken(res?.data?.token);
        toast.success(res);
      } else {
        //show for error
        toast.error(res);
      }
      handleCloseTwo();
    }
  };

  const TabPanel = ({ children, index }) => {
    return (
      <div hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      setLoader(true);
      const { status_code, res } = await getAllProducts();
      if (status_code === 200) {
        const products = res?.products || [];
        const newProds = products.filter((prod) => prod?.is_new);
        const oldProds = products.filter((prod) => !prod?.is_new);
        setNewProducts(newProds);
        setOldProducts(oldProds);
        setAllProducts(products);
        console.log(products);
        setLoader(false);
      } else {
        // error toaster here
        toast.error(res);
        setLoader(false);
      }
      // forceUpdate();
    };

    fetchAllProducts();
  }, [location.pathname]);

  useEffect(() => {
    const fetchAllBrands = async () => {
      const limit = 8;
      const { status_code, res } = await getAllCompany(limit);
      if (status_code === 200) {
        setBrands(res?.brandsDeatails);
      } else {
        toast.error(res);
      }
    };

    fetchAllBrands();
  }, []);

  const BrandData = [
    {
      imgUrl: "/images/mahindra.png",
    },
    {
      imgUrl: "/images/tata.png",
    },
    {
      imgUrl: "/images/mahindra.png",
    },
    {
      imgUrl: "/images/tata.png",
    },
    {
      imgUrl: "/images/mahindra.png",
    },
    {
      imgUrl: "/images/tata.png",
    },
    {
      imgUrl: "/images/mahindra.png",
    },
    {
      imgUrl: "/images/tata.png",
    },
  ];

  return (
    <>
      <Header handleOpen={handleOpen} />
      <Container>
        <ToastContainer />

        {!searchLoader && !clickSearched ? (
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                bgcolor: "#E8F1EA",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    color: "#09753A !important",
                    fontFamily: "mySecondFont",
                  }}
                  label="Home"
                />
                <Tab
                  sx={{
                    color: "#09753A !important",
                    fontFamily: "mySecondFont",
                  }}
                  label="Old Vehicle"
                />
                <Tab
                  sx={{
                    color: "#09753A !important",
                    fontFamily: "mySecondFont",
                  }}
                  label="New Vehicle"
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TopSwiper />

              {/* Cards */}
              <div className={HomeCSS.cardsDiv}>
                <h3
                  className={HomeCSS.mainHeading}
                  style={{ color: "#484848" }}
                >
                  Popular Commercial Electric Vehicles{" "}
                </h3>
                <Cards
                  setModalLabel={setModalLabel}
                  handleOpenBuyNow={handleOpenBuyNow}
                  handleOpen={handleOpen}
                  allProducts={allProducts}
                  loader={loader}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={HomeCSS.cardsDiv}>
                <h3 className={HomeCSS.mainHeading}>Old Vehicle</h3>
                <Cards
                  setModalLabel={setModalLabel}
                  allProducts={oldProducts}
                  handleOpenBuyNow={handleOpenBuyNow}
                  handleOpen={handleOpen}
                  loader={loader}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className={HomeCSS.cardsDiv}>
                <h3 className={HomeCSS.mainHeading}>New Vehicle</h3>
                <Cards
                  setModalLabel={setModalLabel}
                  allProducts={newProducts}
                  handleOpenBuyNow={handleOpenBuyNow}
                  handleOpen={handleOpen}
                  loader={loader}
                />
              </div>
            </TabPanel>
          </Box>
        ) : (
          <>
            {searchLoader ? (
              <Box
                display={"flex"}
                minHeight={"90vh"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {searchedProducts.length === 0 ? (
                  <Box
                    display={"flex"}
                    minHeight={"20vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <h2>No Products available!</h2>
                  </Box>
                ) : (
                  <Box>
                    <div className={HomeCSS.cardsDiv}>
                      <h3
                        className={HomeCSS.mainHeading}
                        style={{ color: "#484848" }}
                      >
                        Results
                      </h3>
                      <Cards
                        setModalLabel={setModalLabel}
                        handleOpenBuyNow={handleOpenBuyNow}
                        handleOpen={handleOpen}
                        allProducts={searchedProducts}
                        loader={loader}
                      />
                    </div>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Container>
      {/* Brands - Section */}
      <Container>
        <div className={HomeCSS.brandDiv}>
          <p>Popular Brands</p>
          <div className={HomeCSS.brandCardDiv}>
            <Row className={HomeCSS.brandRow}>
              {brands.map((item, i) => {
                return (
                  <Col
                    className={HomeCSS.colGap}
                    md={3}
                    key={i}
                    onClick={() => {
                      handleBrandClick(item._id);
                    }}
                  >
                    <div className={HomeCSS.brandCard}>
                      <img
                        style={{
                          objectFit: "scale-down",
                          maxWidth: "306px",
                          height: "144px",
                        }}
                        src="https://www.tata.com/content/dam/tata/images/home-page/desktop/logo_card_desktop_362x362.jpg"
                        alt=""
                        width={"100%"}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </Container>
      <Footer handleOpenContactUs={handleOpenContactUs} />
      <SignUpModal
        handleLoginSubmit={handleLoginSubmit}
        open={open}
        handleClose={handleClose}
        loginNumber={loginNumber}
        setLoginNumber={setLoginNumber}
        label={modalLabel}
      />
      <OtpBox
        openTwo={openTwo}
        handleCloseTwo={handleCloseTwo}
        otp={otp}
        setOtp={setOtp}
        handleSubmitOtp={handleSubmitOtp}
      />
      <BuyNowModal
        label={modalLabel}
        openBuyNow={openBuyNow}
        handleCloseBuyNow={handleCloseBuyNow}
      />
      <ContactUsModal
        openContatUs={openContatUs}
        handleCloseConatctUs={handleCloseConatctUs}
      />
    </>
  );
};

export default Home;
