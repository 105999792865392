import React, { useState } from "react";
import Home from "../components/pages/Home/Home";

const GoogleTranslate = () => {
  const [content, setContent] = useState("i am obito uchiha");

  const translateText = (targetLang) => {
    const apiKey = "AIzaSyAgVb12fByWWG2T9qsO2xoubKjs4QYD91k";
    const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        q: content,
        target: targetLang,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const translatedText = data.data.translations[0].translatedText;
        setContent(translatedText);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  console.log(content);

  return (
    <div>
      <div>
        <button onClick={() => translateText("hi")}>Translate to Hindi</button>
        {/* Add more buttons or dropdowns for other languages */}
      </div>
      <div>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default GoogleTranslate;
