import React, { useEffect, useMemo, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import HeaderCSS from "./Header.module.css";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Divider, Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Face6Icon from "@mui/icons-material/Face6";
import { useSession } from "../../context/authProvider";
import GroupIcon from "@mui/icons-material/Group";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { getAllProducts } from "../../api/Api";
import { toast } from "react-toastify";
import { useSearchProducts } from "../../searchProvider/SearchContext";

const Header = ({ handleOpen }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const { userDetails, setUserDetails } = useSession();
  const {
    setSearchedProducts,
    setSearchLoader,
    search,
    setSearch,
    setClickSearched,
  } = useSearchProducts();

  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorEl2);

  const navigate = useNavigate();
  const logoFunc = () => {
    setClickSearched(false);
    setSearch("");
    navigate("/");
  };

  const handleOpenMenu = (e) => {
    setAnchorEl2(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl2(null);
  };
  const handleProfile = () => {
    navigate("/profilePage");
  };

  const handleLogOut = () => {
    sessionStorage.clear();
    setUserDetails(null);
    setAnchorEl2(null);
    navigate("/");
  };

  const path = useMemo(() => {
    return location.pathname.split("/").find((item) => item === "admin");
  }, [location.pathname]);

  const typeOfUser = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);

  const hideSellPage = () => {
    if (userDetails) {
      if (userDetails?.isOem && userDetails?.isVerified === 1) {
        const typeOfUser = "oem";
        navigate(`/${typeOfUser}/admin`);
      } else if (userDetails?.isFinance && userDetails?.isVerified === 1) {
        const typeOfUser = "finance";
        navigate(`/${typeOfUser}/admin`);
      } else if (
        userDetails?.isIndividualSeller &&
        userDetails?.isVerified === 1
      ) {
        const typeOfUser = "individual";
        navigate(`/${typeOfUser}/admin`);
      } else if (userDetails?.isDealer && userDetails?.isVerified === 1) {
        const typeOfUser = "dealer";
        navigate(`/${typeOfUser}/admin`);
      } else {
        navigate("/sell");
      }
    } else {
      navigate("/sell");
    }
  };

  const handleSearch = async () => {
    if (search !== "") {
      setSearchLoader(true);
      setClickSearched(true);
      const { status_code, res } = await getAllProducts(search);
      if (status_code === 200) {
        const products = res?.products || [];
        setSearchedProducts(products);
        console.log(products);
      } else {
        toast.error(res);
      }
      setSearchLoader(false);
    }
  };

  // useEffect(() => {
  //   if (search !== "") {
  //     let debounceHandler = null;
  //     const fetchAllProducts = async () => {
  //       const { status_code, res } = await getAllProducts(search);
  //       if (status_code === 200) {
  //         const products = res?.products || [];
  //         setSearchedProducts(products);
  //         console.log(products);
  //       } else {
  //         toast.error(res);
  //       }
  //     };

  //     debounceHandler = setTimeout(() => {
  //       if (debounceHandler) {
  //         clearTimeout(debounceHandler);
  //       }
  //       fetchAllProducts();
  //     }, 500);

  //     return () => clearTimeout(debounceHandler);
  //   }
  // }, [search]);

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary py-3">
      <Container>
        <div className={HeaderCSS.logoDiv}>
          <div className={HeaderCSS.logoDiv} onClick={logoFunc}>
            <img
              className={HeaderCSS.logo}
              src="/images/bm-logo-inset.png"
              alt=""
            />
            <img
              className={HeaderCSS.logoText}
              src="/images/logoText.png"
              alt=""
              width={"100%"}
            />
          </div>
          {/* <p className={HeaderCSS.logoText}>BHARAT MOBILITY</p> */}
          <div
            className={`${HeaderCSS.inputMobileDiv} d-block d-lg-none w-100`}
          >
            <input type="text" />
          </div>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto align-items-center gap-2">
            <div className={`${HeaderCSS.inputDiv} d-md-none d-lg-flex`}>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button onClick={handleSearch}>Search</button>
            </div>
            <div
              className={HeaderCSS.langInput}
              id="google_translate_element"
            ></div>
          </Nav>
          <Nav>
            <div className={HeaderCSS.headerbtns}>
              {path === "admin" && (
                <div
                  className={HeaderCSS.navBtn2}
                  onClick={() => {
                    navigate(`/${typeOfUser}/admin/addmanager`);
                  }}
                >
                  <GroupIcon className={HeaderCSS.managerIcon} />
                  <p>Managers</p>
                </div>
              )}
              {path === "admin" && (
                <div
                  className={HeaderCSS.navBtn2}
                  onClick={() => {
                    navigate(`/${typeOfUser}/admin`);
                  }}
                >
                  <AddShoppingCartIcon className={HeaderCSS.productIcon} />
                  <p>Products</p>
                </div>
              )}
              {userDetails?.isSuperAdmin && (
                <div
                  className={HeaderCSS.navBtn2}
                  onClick={() => {
                    navigate("/superAdmin/home");
                  }}
                >
                  <AdminPanelSettingsOutlinedIcon sx={{ color: "green" }} />
                  <p>Super</p>
                </div>
              )}

              {path !== "admin" && (
                <div className={HeaderCSS.navBtn2} onClick={hideSellPage}>
                  <StorefrontOutlinedIcon className={HeaderCSS.sellIcon} />
                  <p>Sell</p>
                </div>
              )}
              <div
                className={HeaderCSS.navBtn1}
                onClick={!userDetails ? handleOpen : handleOpenMenu}
              >
                {!userDetails ? <PersonOutlineOutlinedIcon /> : <Face6Icon />}
                <span style={{ fontFamily: "mySecondFont" }}>
                  {/* {userDetails?.name
                    ? userDetails?.name?.slice(0, 1)
                    : // <PersonOutlineOutlinedIcon />
                      ""} */}
                </span>
              </div>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl2}
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {/* <MenuItem
                  className={HeaderCSS.menuItem}
                  onClick={handleProfile}
                >
                  Profile
                </MenuItem> */}
                <MenuItem className={HeaderCSS.menuItem} onClick={handleLogOut}>
                  Log Out
                </MenuItem>
              </Menu>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
