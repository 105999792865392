import React from "react";
import Header from "../../Header/Header";

const WishList = () => {
  return (
    <>
      <Header />
      <p>Wishlist</p>
    </>
  );
};

export default WishList;
