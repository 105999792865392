import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import CompanyPageCSS from "./CompanyPage.module.css";
import Cards from "../../ProductCard/Cards";
import MuiButton from "../../MuiButton";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllCompany,
  getProductByCompanyId,
  getCompanyById,
} from "../../../api/Api";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";
import ContactUsModal from "../../Modals/ContactUsModal";
import BuyNowModal from "../../Modals/BuyNowModal";

const CompanyPage = () => {
  const [companyProducts, setCompanyProducts] = useState([]);
  const [brandlogo, setBrandLogo] = useState("");
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const handleOpenBuyNow = () => setOpenBuyNow(true);
  const handleCloseBuyNow = () => setOpenBuyNow(false);
  const [openContatUs, setContactUs] = useState(false);
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);

  const navigate = useNavigate();
  useScrollToTop(navigate);
  const params = useParams();
  const companyId = params?.id;
  const isCompanyId = companyId === "undefined" ? false : true;

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const { status_code, res } = await getCompanyById(companyId);
      if (status_code === 200) {
        setBrandLogo(res?.data?.company_logo);
      } else {
        toast.error(res);
      }
    };
    if (isCompanyId) {
      fetchCompanyDetails();
    }
  }, [companyId]);

  useEffect(() => {
    const fetchCompanyProducts = async () => {
      const { status_code, res } = await getProductByCompanyId(companyId);
      if (status_code === 200) {
        setCompanyProducts(res?.data?.products);
      } else {
        toast.error(res);
      }
    };
    if (isCompanyId) {
      fetchCompanyProducts();
    }
  }, [companyId]);
  return (
    <>
      <div>
        <Header />
        <ToastContainer />
        <Container>
          <div className={CompanyPageCSS.mainContainer}>
            <div className={CompanyPageCSS.topsec}>
              <div className={CompanyPageCSS.topsecdata}>
                {/* <div className={CompanyPageCSS.logo}>
                  Brand
                  <br />
                  Image
                </div> */}
                <div className={CompanyPageCSS.brandData}>
                  <img
                    src={brandlogo}
                    alt=""
                    width={"100%"}
                    style={{
                      objectFit: "scale-down",
                      maxWidth: "100px",
                      maxHeight: "100px",
                    }}
                  />
                  {/* <p>Address</p> */}
                </div>
              </div>
              <div className={CompanyPageCSS.topsecbtns}>
                <MuiButton
                  label={"VIEW CONTACT"}
                  bgcolor={"transparent"}
                  color={"#09753a"}
                  width="100%"
                  hoverBg={"transparent"}
                  border="1px solid #09753a"
                />
                <MuiButton
                  label={"SEND EMAIL"}
                  bgcolor={"#09753a"}
                  color={"#fff"}
                  width="100%"
                  hoverBg={"#09753a"}
                />
              </div>
            </div>
            <div className={CompanyPageCSS.midsec}>
              <h3
                style={{
                  fontFamily: "mySecondFont",
                  color: "#484848",
                  textAlign: "start",
                  padding: "10px",
                  marginTop: "24px",
                }}
              >
                Our Products
              </h3>
            </div>
            <div className={CompanyPageCSS.allProducts}>
              {companyProducts?.length > 0 ? (
                <Cards
                  allProducts={companyProducts}
                  handleCloseBuyNow={handleCloseBuyNow}
                />
              ) : (
                <h1>No products available</h1>
              )}
            </div>
          </div>
        </Container>
        <Footer handleOpenContactUs={handleOpenContactUs} />

        <ContactUsModal
          openContatUs={openContatUs}
          handleCloseConatctUs={handleCloseConatctUs}
        />
        <BuyNowModal
          openBuyNow={openBuyNow}
          handleCloseBuyNow={handleCloseBuyNow}
        />
      </div>
    </>
  );
};

export default CompanyPage;
