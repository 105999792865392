import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ProductDetailsCSS from "./ProductDetails.module.css";
import Cards from "../../ProductCard/Cards";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import DetailSection from "./DetailSection";
import SignUpModal from "../../Modals/SignUpModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllProducts,
  getProductById,
  signUpUser,
  submitOtp,
} from "../../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import BuyNowModal from "../../Modals/BuyNowModal";
import Footer from "../../Footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";
import ProductModal from "../../Modals/ProductModal";
import QueryModal from "../../Modals/QueryModal";
import { useSession } from "../../../context/authProvider";
import OtpBox from "../../Modals/OtpBox";
import MuiButton from "../../MuiButton";
import { useSearchProducts } from "../../../searchProvider/SearchContext";
import ContactUsModal from "../../Modals/ContactUsModal";

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  useScrollToTop(navigate);
  const productId = params?.id;
  const { setUserDetails, setToken } = useSession();
  const [productDetails, setProductDetails] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const [openQuery, setOpenQuery] = useState(false);
  const handleOpenBuyNow = () => setOpenBuyNow(true);
  const handleQuery = () => setOpenQuery(true);
  const handleCloseQuery = () => setOpenQuery(false);
  const handleCloseBuyNow = () => setOpenBuyNow(false);
  const [loader, setLoader] = useState(false);
  const [loginNumber, setLoginNumber] = useState("");
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false); // for otp
  const [viewMore, setViewMore] = useState(false);
  const [otp, setOtp] = useState("");

  // contact us modal
  const [openContatUs, setContactUs] = useState(false);
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);

  const [modalLabel, setModalLabel] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenProductModal = () => setOpenProductModal(true);
  const handleClose = () => setOpen(false);
  const handleCloseTwo = () => setOpenTwo(false); // for otp
  const handleProductModalClose = () => setOpenProductModal(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { searchedProducts, searchLoader, clickSearched, search } =
    useSearchProducts();

  useEffect(() => {
    const getProductDetails = async () => {
      const { status_code, res } = await getProductById(productId);
      if (status_code === 200) {
        const details = res?.data || {};
        console.log(details);
        setProductDetails(details);
      } else {
        // error toaster here
        toast.error(res);
      }
    };

    const fetchAllProducts = async () => {
      setLoader(true);
      const { status_code, res } = await getAllProducts();
      if (status_code === 200) {
        const products = res?.products || [];
        setAllProducts(products);
        setLoader(false);
      } else {
        // error toaster here
        toast.error(res);
        setLoader(false);
      }
      // forceUpdate();
    };

    getProductDetails();
    fetchAllProducts();
  }, [productId]);

  const handleLoginSubmit = async () => {
    if (loginNumber !== "") {
      const form = {
        phone_number: loginNumber,
      };
      const { status_code, res } = await signUpUser(form);
      if (status_code === 200) {
        // show toaster for success
      } else {
        //show for error
      }
      handleClose();
      handleProductModalClose();
      setOpenTwo(true);
    }
  };

  const handleSubmitOtp = async () => {
    if (otp !== "") {
      const { status_code, res } = await submitOtp(otp);
      if (status_code === 200) {
        //show toaster for success
        sessionStorage.setItem("userDetails", JSON.stringify(res?.data));
        setUserDetails(res?.data?.user);
        setToken(res?.data?.token);
        toast.success(res);
      } else {
        //show for error
        toast.error(res);
      }
      handleCloseTwo();
    }
  };

  const TabPanel = ({ children, index }) => {
    return (
      <div hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
  return (
    <>
      <Header handleOpen={handleOpen} />

      {/* Cards */}
      <Container>
        <ToastContainer />
        {/* Top - Section */}
        {!searchLoader && !clickSearched ? (
          <>
            <DetailSection
              setModalLabel={setModalLabel}
              open={open}
              openProductModal={openProductModal}
              handleOpen={handleOpenProductModal}
              handleClose={handleOpenProductModal}
              handleProductModalClose={handleProductModalClose}
              openBuyNow={openBuyNow}
              handleQuery={handleQuery}
              handleOpenBuyNow={handleOpenBuyNow}
              handleCloseBuyNow={handleCloseBuyNow}
              productDetails={productDetails}
            />

            <div className={ProductDetailsCSS.cardsDiv}>
              <h3 className={ProductDetailsCSS.mainHeading}>
                Similar Electric 3 Wheelers
              </h3>
              {allProducts.length >= 8 && !viewMore ? (
                <>
                  <Cards
                    setModalLabel={setModalLabel}
                    loader={loader}
                    openBuyNow={openBuyNow}
                    handleOpen={handleOpen}
                    allProducts={allProducts.slice(0, 8)}
                    handleOpenBuyNow={handleOpenBuyNow}
                    handleCloseBuyNow={handleCloseBuyNow}
                  />{" "}
                  <MuiButton
                    onClick={() => setViewMore(true)}
                    label={"VIEW MORE"}
                    bgcolor={"#fff"}
                    color={"#000"}
                    hoverBg={"#09753A"}
                    hoverColor={"#fff"}
                    border="1px solid #000"
                    mt="16px"
                    width="20%"
                    py="12px"
                  />
                </>
              ) : (
                <>
                  <Cards
                    setModalLabel={setModalLabel}
                    loader={loader}
                    openBuyNow={openBuyNow}
                    handleOpen={handleOpen}
                    allProducts={allProducts}
                    handleOpenBuyNow={handleOpenBuyNow}
                    handleCloseBuyNow={handleCloseBuyNow}
                  />
                  <MuiButton
                    onClick={() => setViewMore(false)}
                    label={"VIEW LESS"}
                    bgcolor={"#fff"}
                    color={"#000"}
                    hoverBg={"#09753A"}
                    hoverColor={"#fff"}
                    border="1px solid #000"
                    mt="16px"
                    width="20%"
                    py="12px"
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {searchLoader ? (
              <Box
                display={"flex"}
                minHeight={"90vh"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {searchedProducts.length === 0 ? (
                  <Box
                    display={"flex"}
                    minHeight={"20vh"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <h2>No Products available!</h2>
                  </Box>
                ) : (
                  <Box>
                    <div className={ProductDetailsCSS.cardsDiv}>
                      <h3
                        className={ProductDetailsCSS.mainHeading}
                        style={{ color: "#484848" }}
                      >
                        Results
                      </h3>
                      <Cards
                        setModalLabel={setModalLabel}
                        handleOpenBuyNow={handleOpenBuyNow}
                        handleOpen={handleOpen}
                        allProducts={searchedProducts}
                        loader={loader}
                      />
                    </div>
                  </Box>
                )}
              </>
            )}
          </>
        )}
        {/* ---- About Product ---- */}
        <div className={ProductDetailsCSS.productTabs}>
          <Row>
            <Col md={8}>
              <Box sx={{ width: "100%", background: "#fff" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    bgcolor: "#E8F1EA",
                  }}
                >
                  <Tabs
                    className={ProductDetailsCSS.productTabBar}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ color: "#09753A !important" }}
                      label="Product Details"
                      className={ProductDetailsCSS.tabbtn}
                    />
                    <Tab
                      label="Company Details"
                      className={ProductDetailsCSS.tabbtn}
                    />
                  </Tabs>
                </Box>
                <TabPanel
                  className={ProductDetailsCSS.tabpanel}
                  value={value}
                  index={0}
                >
                  <div className={ProductDetailsCSS.proDetailsSection}>
                    <div className={ProductDetailsCSS.detailsAllCategory}>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Model</h3>
                        <p>{productDetails?.model}</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Price</h3>
                        <p>{productDetails?.price}</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>KM Driven</h3>
                        <p>{productDetails?.kms_driven}</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>City</h3>
                        <p>{productDetails?.city}</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>State</h3>
                        <p>{productDetails?.state}</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Description</h3>
                        <p className={ProductDetailsCSS.descriptiondata}>
                          {productDetails?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className={ProductDetailsCSS.proDetailsSection}>
                    <div className={ProductDetailsCSS.detailsAllCategory}>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Company Name</h3>
                        <p>XYZ</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Price</h3>
                        <p>22000</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Motor Age</h3>
                        <p>1 Year</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Battery Age</h3>
                        <p>2 Year</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Charger Age</h3>
                        <p>6 Months</p>
                      </div>
                      <div className={ProductDetailsCSS.detailsCategory}>
                        <h3>Description</h3>
                        <p>About Product</p>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </Col>
            <Col md={4} className={ProductDetailsCSS.companyCol}>
              <img
                style={{ maxWidth: "325px", width: "100%" }}
                // src={productDetails?.images[0]}
                alt=""
              />
            </Col>
          </Row>
        </div>
        <SignUpModal
          label={modalLabel}
          handleLoginSubmit={handleLoginSubmit}
          handleClose={handleClose}
          open={open}
          loginNumber={loginNumber}
          setLoginNumber={setLoginNumber}
        />
        <OtpBox
          openTwo={openTwo}
          handleCloseTwo={handleCloseTwo}
          otp={otp}
          setOtp={setOtp}
          handleSubmitOtp={handleSubmitOtp}
        />
        <ProductModal
          label={modalLabel}
          handleClose={handleProductModalClose}
          open={openProductModal}
          productDetails={productDetails}
          loginNumber={loginNumber}
          setLoginNumber={setLoginNumber}
          handleLoginSubmit={handleLoginSubmit}
        />
        <BuyNowModal
          label={modalLabel}
          openBuyNow={openBuyNow}
          handleCloseBuyNow={handleCloseBuyNow}
        />
        <QueryModal
          label={modalLabel}
          productDetails={productDetails}
          openQuery={openQuery}
          handleCloseQuery={handleCloseQuery}
        />
      </Container>
      <Footer handleOpenContactUs={handleOpenContactUs} />
      <ContactUsModal
        openContatUs={openContatUs}
        handleCloseConatctUs={handleCloseConatctUs}
      />
    </>
  );
};

export default ProductDetails;
