import ProductDetails from "./components/pages/Product/ProductDetails";
import Home from "./components/pages/Home/Home";
import SellPage from "./components/pages/Sell/SellPage";
import SignUpPage from "./components/pages/SignUp/SignUpPage";
import AdminPage from "./components/pages/Admin/AdminPage";
import AddProductPage from "./components/pages/AddProduct/AddProductPage";
import AddManagerPage from "./components/pages/AddManager/AddManagerPage";
import CompanyPage from "./components/pages/CompanyPage/CompanyPage";
import SuperAdminPage from "../src/components/pages/SuperAdmin/SuperAdminPage";
import WishList from "./components/pages/Wishlist/WishList";
import ProfilePage from "./components/pages/ProfilePage/ProfilePage";
import WishListAdminPage from "./components/pages/WishListAdmin/WishListAdminPage";

export const privateRoutes = [
  {
    path: "/:id/admin/addproduct",
    element: <AddProductPage />,
  },
  {
    path: "/:id/admin/addmanager",
    element: <AddManagerPage />,
  },

  {
    path: "/:seller/admin",
    element: <AdminPage />,
  },

  {
    path: "/superAdmin/home",
    element: <SuperAdminPage />,
  },
  {
    path: "*",
    element: "not found",
  },
];

export const publicRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/product/:id",
    element: <ProductDetails />,
  },
  {
    path: "/sell",
    element: <SellPage />,
  },
  {
    path: "/signUp/:seller",
    element: <SignUpPage />,
  },
  {
    path: "/companyPage/:id",
    element: <CompanyPage />,
  },
  {
    path: "/wishlist",
    element: <WishList />,
  },
  {
    path: "/profilePage",
    element: <ProfilePage />,
  },
  {
    path: "/wishList/:id",
    element: <WishListAdminPage />,
  },

  {
    path: "*",
    element: "not found",
  },
];
