import React from "react";
import { Box, Grid } from "@mui/material";
import { Col } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";

// import required modules

const CardSkeleton = () => {
  return (
    <Col md={3}>
      <Box borderRadius={"4px"} border={"1px solid #C6C6C6"} margin={1}>
        <Box
          borderBottom={"1px solid #C6C6C6"}
          display={"flex"}
          justifyContent={"center"}
          height={"40%"}
          sx={{ cursor: "pointer" }}
          // onClick={() => {
          //   navigate(`/product/${prod.id}`);
          // }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            animation="wave"
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={"16px"}
          alignItems={"flex-start"}
        >
          <Skeleton width={150} animation="wave" variant="text" />

          <Skeleton width={100} animation="wave" variant="text" />

          <Skeleton width={80} animation="wave" variant="text" />

          <Grid container sx={{ py: "4px" }} columnSpacing={"32px"}>
            <Grid item xs={6} sm={6}>
              <Skeleton width={50} animation="wave" variant="text" />

              <Skeleton width={80} animation="wave" variant="text" />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Skeleton width={50} animation="wave" variant="text" />

              <Skeleton width={80} animation="wave" variant="text" />
            </Grid>
          </Grid>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
          pb={"16px"}
          px={"16px"}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            animation="wave"
          />

          <Skeleton
            variant="rectangular"
            width="100%"
            height={40}
            animation="wave"
          />
        </Box>
      </Box>
    </Col>
  );
};

export default CardSkeleton;
