// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyPage_mainContainer__B7MNt {
  background-color: #fff;
  padding: 16px;
}
.CompanyPage_topsec__AI5VO {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
}
.CompanyPage_topsecdata__63z4M {
  display: flex;
  gap: 12px;
}
.CompanyPage_topsecbtns__YQBLh {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.CompanyPage_logo__gTID1 {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CompanyPage_brandData__lUYdL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.CompanyPage_brandData__lUYdL h3 {
  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);
  font-family: mySecondFont;
  color: #484848;
  text-align: start;
}
.CompanyPage_brandData__lUYdL p {
  font-size: clamp(1rem, 0.9481rem + 0.2516vw, 1.25rem);
  font-family: mySecondFont;
  color: #484848;
}

/* midsec */
.CompanyPage_midsec__CrYH\\+ {
  margin-top: 32px;
}
.CompanyPage_midsec__CrYH\\+ h2 {
  font-family: mySecondFont;
  color: #484848;
  font-size: clamp(1.5rem, 1.3443rem + 0.7547vw, 2.25rem);
}
.CompanyPage_allProducts__5ckbO {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/CompanyPage/CompanyPage.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,6BAA6B;EAC7B,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,qDAAqD;EACrD,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,qDAAqD;EACrD,yBAAyB;EACzB,cAAc;AAChB;;AAEA,WAAW;AACX;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,uDAAuD;AACzD;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".mainContainer {\n  background-color: #fff;\n  padding: 16px;\n}\n.topsec {\n  display: flex;\n  padding-bottom: 16px;\n  border-bottom: 1px solid #ccc;\n  justify-content: space-between;\n  align-items: center;\n}\n.topsecdata {\n  display: flex;\n  gap: 12px;\n}\n.topsecbtns {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.logo {\n  width: 80px;\n  height: 80px;\n  border: 1px solid #ccc;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.brandData {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n.brandData h3 {\n  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);\n  font-family: mySecondFont;\n  color: #484848;\n  text-align: start;\n}\n.brandData p {\n  font-size: clamp(1rem, 0.9481rem + 0.2516vw, 1.25rem);\n  font-family: mySecondFont;\n  color: #484848;\n}\n\n/* midsec */\n.midsec {\n  margin-top: 32px;\n}\n.midsec h2 {\n  font-family: mySecondFont;\n  color: #484848;\n  font-size: clamp(1.5rem, 1.3443rem + 0.7547vw, 2.25rem);\n}\n.allProducts {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `CompanyPage_mainContainer__B7MNt`,
	"topsec": `CompanyPage_topsec__AI5VO`,
	"topsecdata": `CompanyPage_topsecdata__63z4M`,
	"topsecbtns": `CompanyPage_topsecbtns__YQBLh`,
	"logo": `CompanyPage_logo__gTID1`,
	"brandData": `CompanyPage_brandData__lUYdL`,
	"midsec": `CompanyPage_midsec__CrYH+`,
	"allProducts": `CompanyPage_allProducts__5ckbO`
};
export default ___CSS_LOADER_EXPORT___;
