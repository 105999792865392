import { Button } from "@mui/material";
import React from "react";

const MuiButton = ({
  color,
  bgcolor,
  fontWeight,
  fontFamily = "",
  hoverBg,
  onClick,
  label,
  border = "",
  mt = "",
  px = "",
  py = "",
  btlr = "",
  bblr = "",
  width = "",
  hoverColor = "",
  Icon,
}) => {
  return (
    <Button
      endIcon={Icon ? <Icon /> : null}
      sx={{
        width: width,
        border: border,
        mt: mt,
        px: px,
        py: py,
        borderTopLeftRadius: btlr,
        borderBottomLeftRadius: bblr,
        color,
        bgcolor,
        fontFamily,
        fontWeight,
        ":hover": {
          bgcolor: hoverBg,
          color: hoverColor,
        },
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default MuiButton;
