// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SellerCard_allCards__sAG\\+n {
  background-color: #fff;
  padding: 64px 0px;
}
.SellerCard_soloCard__JizOd {
  padding: 24px 16px;
  background-color: #d2e3d5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}
.SellerCard_soloCard__JizOd h3 {
  color: #484848;
  font-size: clamp(1.125rem, 0.9953rem + 0.6289vw, 1.75rem);
  font-family: mySecondFont;
}
.SellerCard_soloCard__JizOd p {
  color: #484848;
  font-size: clamp(1rem, 0.9481rem + 0.2516vw, 1.25rem);
  font-family: myThirdFont;
}

@media screen and (max-width: 992px) {
  .SellerCard_SellerCardDiv__dU4TJ {
    display: flex;
    gap: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Sell/SellerCard.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,cAAc;EACd,yDAAyD;EACzD,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,qDAAqD;EACrD,wBAAwB;AAC1B;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;AACF","sourcesContent":[".allCards {\n  background-color: #fff;\n  padding: 64px 0px;\n}\n.soloCard {\n  padding: 24px 16px;\n  background-color: #d2e3d5;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 220px;\n  flex-direction: column;\n  border-radius: 5px;\n  cursor: pointer;\n}\n.soloCard h3 {\n  color: #484848;\n  font-size: clamp(1.125rem, 0.9953rem + 0.6289vw, 1.75rem);\n  font-family: mySecondFont;\n}\n.soloCard p {\n  color: #484848;\n  font-size: clamp(1rem, 0.9481rem + 0.2516vw, 1.25rem);\n  font-family: myThirdFont;\n}\n\n@media screen and (max-width: 992px) {\n  .SellerCardDiv {\n    display: flex;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allCards": `SellerCard_allCards__sAG+n`,
	"soloCard": `SellerCard_soloCard__JizOd`,
	"SellerCardDiv": `SellerCard_SellerCardDiv__dU4TJ`
};
export default ___CSS_LOADER_EXPORT___;
