import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home/Home";
import { privateRoutes, publicRoutes } from "./routes";
import "react-toastify/dist/ReactToastify.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useSession } from "./context/authProvider";
function App() {
  const { token } = useSession();

  return (
    <div className="App">
      <Routes>
        {/* both private and public routes should have a path which points to "*" */}
        {token &&
          privateRoutes.map((pr) => (
            <Route key={pr.path} path={pr.path} element={pr.element} />
          ))}
        {publicRoutes.map((pr) => (
          <Route key={pr.path} path={pr.path} element={pr.element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
