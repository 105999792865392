import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const authContext = createContext();

const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    const sessionData = JSON.parse(sessionStorage.getItem("userDetails"));
    setUserDetails(sessionData?.user);
    setToken(sessionData?.token);
  }, []);

  return (
    <authContext.Provider
      value={{
        userDetails,
        setUserDetails,
        token,
        setToken,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
export const useSession = () => {
  return useContext(authContext);
};

export default AuthProvider;
