import React, { useEffect } from "react";

const useScrollToTop = (navigate) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 200);
  }, [navigate]);
};

export default useScrollToTop;
