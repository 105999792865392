import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../../context/authProvider";
import MuiButton from "../MuiButton";
import { addToWishList } from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  // p: 4,
};

const QueryModal = ({ productDetails, openQuery, handleCloseQuery, label }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [productQuery, setProductQuery] = useState();
  const { token } = useSession();

  const path = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);

  const prodId = productDetails?._id;

  const handleProductQuery = async () => {
    const { status_code, res } = await addToWishList(prodId, token);
    if (status_code === 200) {
      //   toast.success(res?.message);
      toast.success("Thank You For Your Interest, We will Contact you soon!");
      setProductQuery(res);
    } else {
      //   toast.error(res);
      toast.success("Thank You For Your Interest, We will Contact you soon!");
    }
  };
  return (
    <div>
      <Modal
        open={openQuery}
        onClose={handleCloseQuery}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              //   gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                borderRight: "1px solid #484848",
                // "@media (max-width:992px)": {
                //   width: "250px",
                //   maxWidth: "100%",
                //   borderRight: "1px solid #484848",
                // },
                "@media (max-width:992px)": {
                  display: "none",
                  width: "0%",
                },
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: "3px",
                }}
                src={productDetails?.images?.[0]}
                alt=""
              />
              <Box
                sx={{
                  margin: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    price
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                      textAlign: "end",
                    }}
                  >
                    ₹{productDetails?.price}
                  </p>
                </span>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    State
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    {productDetails?.state}
                  </p>
                </span>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    City
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    {productDetails?.city}
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "30px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    Pincode
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    -
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    {productDetails?.pincode}
                  </p>
                </span>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "12px",
                backgroundColor: "#f5f5f5",
                padding: "76px 0px",
                paddingLeft: "32px",
                // "@media (max-width:992px)": {
                //   display: "flex",
                //   width: "0%",
                // },
              }}
            >
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "20px",
                  paddingLeft: "4px",
                }}
              >
                Please provide below details to{" "}
                <span style={{ fontWeight: 600 }}>{label}</span>
              </p>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "6px",
                }}
              >
                <label
                  htmlFor="quantity"
                  style={{ fontFamily: "mySecondFont", color: "#484848" }}
                >
                  Quantity
                </label>
                <input
                  defaultValue={1}
                  type="number"
                  style={{ width: "100px", borderRadius: "4px" }}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "6px",
                }}
              >
                <label
                  htmlFor="quantity"
                  style={{
                    fontFamily: "mySecondFont",
                    color: "#484848",
                  }}
                >
                  Brand
                </label>
                <input
                  type="text"
                  value={productDetails?.brand}
                  disabled
                  style={{
                    padding: "8px",
                    width: "350px",
                    borderRadius: "8px",
                  }}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "6px",
                }}
              >
                <label
                  htmlFor="quantity"
                  style={{ fontFamily: "mySecondFont", color: "#484848" }}
                >
                  Model
                </label>
                <input
                  type="text"
                  value={productDetails?.model}
                  disabled
                  style={{
                    padding: "8px",
                    width: "350px",
                    borderRadius: "8px",
                  }}
                />
              </span>
              <span style={{ padding: "6px" }}>
                <MuiButton
                  mt="10px"
                  width="150px"
                  label={"Submit"}
                  bgcolor={"gray"}
                  color={"#fff"}
                  fontFamily="mySecondFont"
                  hoverBg={"grey"}
                  py="8px"
                  onClick={handleProductQuery}
                />
              </span>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default QueryModal;
