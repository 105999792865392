// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainForm_mainHeading__dWPuq {
  font-family: mySecondFont;
  padding: 32px 0px;
}
.MainForm_mainContainer__eznv9 {
  background-color: #fff;
  margin: 32px 0px;
}
.MainForm_mainForm__Ctbav {
  background-color: #fff;
  padding: 32px 0px;
  border-radius: 5px;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.MainForm_formGroup__03e8n {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  max-width: 800px;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

/* label - input */
.MainForm_label__qq7Ez {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-family: mySecondFont;
  font-size: clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem);
}
.MainForm_submitBtn__bYBw- {
  display: flex;
  justify-content: flex-start;
}
.MainForm_submitBtn__bYBw- button {
  background-color: #484848;
  color: #fff;
  font-family: mySecondFont;
  border: none;
  border-radius: 3px;
  padding: 10px 16px;
}
.MainForm_customField__mdKNq {
  padding: 14px 8px !important;
  font-family: myThirdFont;
}
`, "",{"version":3,"sources":["webpack://./src/components/Forms/MainForm.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;AAEA,kBAAkB;AAClB;EACE,aAAa;EACb,2BAA2B;EAC3B,WAAW;EACX,yBAAyB;EACzB,sDAAsD;AACxD;AACA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,wBAAwB;AAC1B","sourcesContent":[".mainHeading {\n  font-family: mySecondFont;\n  padding: 32px 0px;\n}\n.mainContainer {\n  background-color: #fff;\n  margin: 32px 0px;\n}\n.mainForm {\n  background-color: #fff;\n  padding: 32px 0px;\n  border-radius: 5px;\n  display: flex;\n  gap: 24px;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.formGroup {\n  display: flex;\n  justify-content: center;\n  /* align-items: center; */\n  max-width: 800px;\n  flex-direction: column;\n  gap: 2px;\n  width: 100%;\n}\n\n/* label - input */\n.label {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  font-family: mySecondFont;\n  font-size: clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem);\n}\n.submitBtn {\n  display: flex;\n  justify-content: flex-start;\n}\n.submitBtn button {\n  background-color: #484848;\n  color: #fff;\n  font-family: mySecondFont;\n  border: none;\n  border-radius: 3px;\n  padding: 10px 16px;\n}\n.customField {\n  padding: 14px 8px !important;\n  font-family: myThirdFont;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainHeading": `MainForm_mainHeading__dWPuq`,
	"mainContainer": `MainForm_mainContainer__eznv9`,
	"mainForm": `MainForm_mainForm__Ctbav`,
	"formGroup": `MainForm_formGroup__03e8n`,
	"label": `MainForm_label__qq7Ez`,
	"submitBtn": `MainForm_submitBtn__bYBw-`,
	"customField": `MainForm_customField__mdKNq`
};
export default ___CSS_LOADER_EXPORT___;
