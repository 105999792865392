import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import MuiButton from "../MuiButton";
import { TextField, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../../context/authProvider";
import QueryModal from "./QueryModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  // p: 4,
};

const ProductModal = ({
  handleLoginSubmit,
  setLoginNumber,
  loginNumber,
  productDetails,
  open,
  handleClose,
  label = "",
}) => {
  const { userDetails } = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("home");
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  const path = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);
  // path === "signUp" && !userDetails ? null :
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                width: "45%",
                padding: "24px",
                "@media (max-width:992px)": {
                  width: "250px",
                  maxWidth: "100%",
                  borderRight: "1px solid #484848",
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img width={"60px"} src="/images/bm-logo-inset.png" alt="" />
                <p
                  style={{
                    fontFamily: "myFirstFont",
                    color: "#484848",
                  }}
                >
                  BHARAT
                  <br />
                  MOBILITY
                </p>
              </div>

              <Box
                sx={{
                  mt: 3,
                  borderBottom: 1,
                  borderColor: "divider",
                  bgcolor: "#fff",
                }}
              >
                <p style={{ fontWeight: 400, fontSize: "20px" }}>
                  Please enter your Phone Number to{" "}
                  <span style={{ fontWeight: 600 }}>{label}</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "32px",
                    flexDirection: "column",
                    marginTop: "18px",
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      autoFocus
                      value={loginNumber}
                      onChange={(e) => setLoginNumber(e.target.value)}
                      id="standard-basic"
                      label="Phone Number"
                      variant="standard"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Box>

                  <span>
                    <MuiButton
                      onClick={handleLoginSubmit}
                      label={"SUBMIT"}
                      bgcolor={"#2ea3c5"}
                      color={"#fff"}
                      width="100%"
                      hoverBg={"#2ea3c5"}
                      fontFamily="mySecondFont"
                    />
                  </span>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { width: "100%" },
                      opacity: 0,
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      autoFocus
                      sx={{ opacity: 0 }}
                      value={loginNumber}
                      onChange={(e) => setLoginNumber(e.target.value)}
                      id="standard-basic"
                      label="Phone Number"
                      variant="standard"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Box>
                </div>
                {/* </Tab>
                </Tabs> */}
              </Box>
            </Box>
            <Box
              sx={{
                width: "55%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#e5e3e3",
                "@media (max-width:992px)": {
                  display: "none",
                  width: "0%",
                },
              }}
            >
              <img
                style={{
                  width: "100%",
                  borderRadius: "3px",
                }}
                src={productDetails?.images?.[0]}
                alt=""
              />
              <Box
                sx={{
                  margin: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  width: "100%",
                  padding: "0px 20px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "66px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    Brand
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    -
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                    }}
                  >
                    {productDetails?.brand}
                  </p>
                </span>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    Model
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    {productDetails?.model}
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    price
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p
                    style={{
                      fontFamily: "myThirdFont",
                      fontSize: "16px",
                      textAlign: "end",
                    }}
                  >
                    {productDetails?.price}
                  </p>
                </span>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    City
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    -
                  </p>
                  <p style={{ fontFamily: "myThirdFont", fontSize: "16px" }}>
                    {productDetails?.city}
                  </p>
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ProductModal;
