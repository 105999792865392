import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import AdminTopBar from "./AdminTopBar";
import AdminProductsSection from "./AdminProductsSection";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import AddManager from "../../Modals/AddManager";
import { createManager, getAdminProducts } from "../../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import { useSession } from "../../../context/authProvider";
import Footer from "../../Footer/Footer";
import ContactUsModal from "../../Modals/ContactUsModal";

const AdminPage = () => {
  const { token } = useSession();
  const [open, setOpen] = useState(false);
  const [managerEmail, setManagerEmail] = useState("");
  const [adminProducts, setAdminProducts] = useState([]);
  const [openContatUs, setContactUs] = useState(false);
  const [range, setRange] = useState("");
  const [price, setPrice] = useState("");
  const handleOpenContactUs = () => setContactUs(true);
  const handleCloseConatctUs = () => setContactUs(false);

  const handleChange = (e) => {
    setManagerEmail(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleSubmitManagerForm = async () => {
    if (!managerEmail) {
      toast.error("Enter an email");
      return;
    }

    const { status_code, res } = await createManager(managerEmail, token);
    if (status_code === 200) {
      handleClose();
    } else {
      toast.error(res);
    }
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      // user is null in product
      const { status_code, res } = await getAdminProducts(token, range, price);
      if (status_code === 200) {
        const products = res?.products || [];
        setAdminProducts(products);
        console.log(products);
      } else {
        toast.error(res);
      }
    };

    fetchAllProducts();
  }, [range, price]);
  return (
    <>
      <Header />
      <ToastContainer />
      {/* Admin Top Bar */}
      <AdminTopBar
        handleClick={handleClick}
        setRange={setRange}
        range={range}
        price={price}
        setPrice={setPrice}
      />

      {/* Admin Product Card */}
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "9px",
            background: "white",
            padding: "8px 16px",
          }}
        >
          <h3 style={{ fontFamily: "mySecondFont", color: "#484848" }}>
            All Products
          </h3>
        </div>
      </Container>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <AdminProductsSection adminProducts={adminProducts} />
      </div>

      <AddManager
        handleSubmitManagerForm={handleSubmitManagerForm}
        handleClose={handleClose}
        open={open}
        handleChange={handleChange}
        managerEmail={managerEmail}
      />
      <Footer handleOpenContactUs={handleOpenContactUs} />
      <ContactUsModal
        openContatUs={openContatUs}
        handleCloseConatctUs={handleCloseConatctUs}
      />
    </>
  );
};

export default AdminPage;
