import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWishList } from "../../../api/Api";
import { toast } from "react-toastify";
import Header from "../../Header/Header";
import { Box } from "@mui/material";
import Products from "./products";
import { Container } from "react-bootstrap";
import Footer from "../../Footer/Footer";
import useScrollToTop from "../../../hooks/useScrollToTop";

const WishListAdminPage = () => {
  const params = useParams();
  const userId = params?.id;

  const navigate = useNavigate();
  useScrollToTop(navigate);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    const fetchAllInterestedProducts = async () => {
      const { status_code, res } = await getWishList(userId);
      if (status_code === 200) {
        const products = res?.product || [];
        console.log("prod", products);
        setAllProducts(products);
      } else {
        toast.error(res);
      }
    };

    fetchAllInterestedProducts();
  }, []);
  return (
    <>
      <Header />
      {allProducts.length === 0 ? (
        <Box
          minHeight={"89vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <h1>No Data Available</h1>
        </Box>
      ) : (
        <Container style={{ marginBottom: "8px" }}>
          <h3
            style={{
              fontFamily: "mySecondFont",
              color: "#484848",
              textAlign: "start",
              padding: "10px",
              marginTop: "24px",
              paddingBottom: "24px",
            }}
          >
            Interested Products
          </h3>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {allProducts.map((prod) => (
              <Products key={prod._id} prod={prod} />
            ))}
          </Box>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default WishListAdminPage;
