import { Box, CircularProgress, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useRef, useState, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import ProductFormCSS from "./ProductForm.module.css";
import { Tabs, Tab } from "@mui/material";
import MuiButton from "../MuiButton";
import { createProduct } from "../../api/Api";
import { useSession } from "../../context/authProvider";
import { ToastContainer, toast } from "react-toastify";
// import AWS from "aws-sdk";

const ProductForm = () => {
  const { token } = useSession();

  const location = useLocation();
  const navigate = useNavigate();
  const input1Ref = useRef(null);
  const image1Ref = useRef(null);
  const input2Ref = useRef(null);
  const image2Ref = useRef(null);
  const input3Ref = useRef(null);
  const image3Ref = useRef(null);
  const input4Ref = useRef(null);
  const image4Ref = useRef(null);
  const input5Ref = useRef(null);
  const image5Ref = useRef(null);

  const typeOfUser = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);

  const [value, setValue] = useState(typeOfUser === "individual" ? 1 : 0);

  console.log(typeOfUser, "typeOfUser ");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = ({ children, index }) => {
    return (
      <div hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const handleImageUpload = (e, imageRef, index, setFieldValue) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (imageRef.current) {
          imageRef.current.src = event.target.result;
        }
        // Update Formik state using setFieldValue
      };
      reader.readAsDataURL(file);
      // setImages([...images, file]);
    }
  };

  const handleFormData = (values) => {
    console.log(values);
    const formData = new FormData();
    formData.append("file1", values?.image1);
    formData.append("file2", values?.image2);
    formData.append("file3", values?.image3);
    formData.append("file4", values?.image4);
    formData.append("file5", values?.image5);
    formData.append("name", values?.name);
    formData.append("brand", values?.brand);
    formData.append("model", values?.model);
    formData.append("state", values?.state);
    formData.append("city", values?.city);
    formData.append("country", values?.country);
    formData.append("pincode", values?.pincode);
    formData.append("price", values?.price);
    formData.append("number_of_owners", values?.number_of_owners);
    formData.append("registration_number", values?.registration_number);
    formData.append("insurance_expiry", values?.insurance_expiry);
    formData.append("registered_at", values?.registered_at);
    formData.append("kms_driven", values?.kms_driven);
    formData.append("description", values?.description);
    formData.append("motor_change_date", values?.motor_change_date);
    formData.append("battery_change_date", values?.battery_change_date);
    // formData.append("charger_age", values?.charger_age);

    console.log("formmmmmmmmmmmmmmmmmm", formData);
    return formData;
  };

  const initialValues = {
    image1: null || "",
    image2: null || "",
    image3: null || "",
    image4: null || "",
    image5: null || "",
    name: "",
    brand: "",
    model: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
    price: "",
    number_of_owners: "",
    registration_number: "",
    insurance_expiry: "",
    registered_at: "",
    kms_driven: "",
    description: "",
    motor_change_date: "",
    battery_change_date: "",
  };

  const validationSchema = Yup.object({
    image1: Yup.mixed().required("Required"),
    image2: Yup.mixed().required("Required"),
    image3: Yup.mixed().required("Required"),
    image4: Yup.mixed().required("Required"),
    image5: Yup.mixed().required("Required"),
    name: Yup.string().required("Required"),
    brand: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    number_of_owners: Yup.string().required("Required"),
    registration_number: value === 1 && Yup.string().required("Required"),
    insurance_expiry: Yup.string().required("Required"),
    registered_at: value === 1 && Yup.string().required("Required"),
    kms_driven: value === 1 && Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    motor_change_date: value === 1 && Yup.string().required("Required"),
    battery_change_date: value === 1 && Yup.string().required("Required"),
    // charger_age: value === 1 && Yup.string().required("Required"),
  });
  return (
    <>
      <Container>
        <div className={ProductFormCSS.oldNewTabs}>
          <Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  bgcolor: "#E8F1EA",
                }}
              >
                <Tabs
                  className={ProductFormCSS.TabsBtn}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  {typeOfUser !== "individual" && (
                    <Tab
                      disabled={
                        typeOfUser === "individual" || typeOfUser === "oem"
                          ? true
                          : false
                      }
                      className={ProductFormCSS.TabBtn}
                      sx={{
                        color: "#09753A !important",
                        width: "50%",
                        flexGrow: 1,
                        fontFamily: "mySecondFont",
                      }}
                      label="New Product"
                    />
                  )}
                  {typeOfUser !== "oem" && (
                    <Tab
                      disabled={
                        typeOfUser === "individual" || typeOfUser === "oem"
                          ? true
                          : false
                      }
                      className={ProductFormCSS.TabBtn}
                      sx={{
                        color: "#09753A !important",
                        width: "50%",
                        flexGrow: 1,
                        fontFamily: "mySecondFont",
                      }}
                      label={
                        typeOfUser === "individual"
                          ? "Product Details"
                          : "Old Product"
                      }
                    />
                  )}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const formData = handleFormData(values);
                    console.log("mainform", Object.fromEntries(formData));
                    console.log("prod", values);
                    values.registration_number = Date.now();
                    values.registered_at = Date.now();
                    values.kms_driven = 0;
                    values.motor_change_date = Date.now();
                    values.battery_change_date = Date.now();
                    values.number_of_owners = 0;

                    const handleSubmit = async () => {
                      const { status_code, res } = await createProduct(
                        formData,
                        token
                      );
                      if (status_code === 200) {
                        setSubmitting(false);
                        // navigate(`/${typeOfUser.toLowerCase()}/admin`);
                      } else {
                        toast.error(res);
                      }
                    };

                    handleSubmit();
                  }}
                >
                  {({ errors, isSubmitting, touched, setFieldValue }) => (
                    <Form encType="multipart/form-data">
                      <ToastContainer />
                      <Container
                        className={ProductFormCSS.mainContainer}
                        style={{ filter: isSubmitting ? "blur(1px)" : "" }}
                      >
                        <div>
                          {isSubmitting && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                        <h2 className={ProductFormCSS.mainHeading}>
                          {/* SIGN UP AS {typeOfUser.toUpperCase()} */}
                        </h2>
                        <div className={ProductFormCSS.mainForm}>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="name"
                              className={ProductFormCSS.label}
                            >
                              Title
                            </label>{" "}
                            <Field
                              name="name"
                              type="text"
                              className={
                                `form-control ${ProductFormCSS.customField} ` +
                                (errors.name && touched.name
                                  ? "is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="brand"
                              className={ProductFormCSS.label}
                            >
                              Brand
                            </label>
                            <Field
                              name="brand"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.brand && touched.brand
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="brand"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="model"
                              className={ProductFormCSS.label}
                            >
                              Model
                            </label>{" "}
                            <Field
                              name="model"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.model && touched.model
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="model"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="country"
                              className={ProductFormCSS.label}
                            >
                              Country
                            </label>{" "}
                            <Field
                              name="country"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.country && touched.country
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="country"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="state"
                              className={ProductFormCSS.label}
                            >
                              State
                            </label>{" "}
                            <Field
                              name="state"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.state && touched.state
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="state"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="city"
                              className={ProductFormCSS.label}
                            >
                              City
                            </label>{" "}
                            <Field
                              name="city"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.city && touched.city
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="pincode"
                              className={ProductFormCSS.label}
                            >
                              Pincode
                            </label>{" "}
                            <Field
                              name="pincode"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.pincode && touched.pincode
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="pincode"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="price"
                              className={ProductFormCSS.label}
                            >
                              Price
                            </label>{" "}
                            <Field
                              name="price"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.price && touched.price
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="price"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          {value === 1 && (
                            <div className={ProductFormCSS.formGroup}>
                              <label
                                htmlFor="registration_number"
                                className={ProductFormCSS.label}
                              >
                                Vehicle Number
                              </label>{" "}
                              <Field
                                name="registration_number"
                                type="text"
                                style={{ fontFamily: "myThirdFont" }}
                                className={
                                  `form-control  ${ProductFormCSS.customField}` +
                                  (errors.registration_number &&
                                  touched.registration_number
                                    ? " is-invalid"
                                    : "")
                                }
                              />{" "}
                              <ErrorMessage
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                name="registration_number"
                                component="div"
                                className="invalid-feedback"
                              />{" "}
                            </div>
                          )}

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="insurance_expiry"
                              className={ProductFormCSS.label}
                            >
                              Insurance Expiry
                            </label>{" "}
                            <Field
                              name="insurance_expiry"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.insurance_expiry &&
                                touched.insurance_expiry
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="insurance_expiry"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          {value === 1 && (
                            <div className={ProductFormCSS.formGroup}>
                              <label
                                htmlFor="registered_at"
                                className={ProductFormCSS.label}
                              >
                                Date of Registration
                              </label>{" "}
                              <Field
                                name="registered_at"
                                type="text"
                                style={{ fontFamily: "myThirdFont" }}
                                className={
                                  `form-control  ${ProductFormCSS.customField}` +
                                  (errors.registered_at && touched.registered_at
                                    ? " is-invalid"
                                    : "")
                                }
                              />{" "}
                              <ErrorMessage
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                name="registered_at"
                                component="div"
                                className="invalid-feedback"
                              />{" "}
                            </div>
                          )}

                          {value === 1 && (
                            <div className={ProductFormCSS.formGroup}>
                              <label
                                htmlFor="kms_driven"
                                className={ProductFormCSS.label}
                              >
                                Kms Driven
                              </label>
                              <Field
                                name="kms_driven"
                                type="text"
                                style={{ fontFamily: "myThirdFont" }}
                                className={
                                  `form-control  ${ProductFormCSS.customField}` +
                                  (errors.kms_driven && touched.kms_driven
                                    ? " is-invalid"
                                    : "")
                                }
                              />{" "}
                              <ErrorMessage
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                name="kms_driven"
                                component="div"
                                className="invalid-feedback"
                              />{" "}
                            </div>
                          )}

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="description"
                              className={ProductFormCSS.label}
                            >
                              Description
                            </label>{" "}
                            <Field
                              name="description"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.description && touched.description
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="description"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {value === 1 && (
                            <>
                              <div className={ProductFormCSS.formGroup}>
                                <label
                                  htmlFor="motor_change_date"
                                  className={ProductFormCSS.label}
                                >
                                  Motor Age
                                </label>{" "}
                                <Field
                                  name="motor_change_date"
                                  type="text"
                                  style={{ fontFamily: "myThirdFont" }}
                                  className={
                                    `form-control  ${ProductFormCSS.customField}` +
                                    (errors.motor_change_date &&
                                    touched.motor_change_date
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  name="motor_change_date"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className={ProductFormCSS.formGroup}>
                                <label
                                  htmlFor="battery_change_date"
                                  className={ProductFormCSS.label}
                                >
                                  Battery Age
                                </label>
                                <Field
                                  name="battery_change_date"
                                  type="text"
                                  style={{ fontFamily: "myThirdFont" }}
                                  className={
                                    `form-control  ${ProductFormCSS.customField}` +
                                    (errors.battery_change_date &&
                                    touched.battery_change_date
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  name="battery_change_date"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              {/* <div className={ProductFormCSS.formGroup}>
                                <label
                                  htmlFor="charger_age"
                                  className={ProductFormCSS.label}
                                >
                                  Charger Age
                                </label>
                                <Field
                                  name="charger_age"
                                  type="text"
                                  style={{ fontFamily: "myThirdFont" }}
                                  className={
                                    `form-control  ${ProductFormCSS.customField}` +
                                    (errors.charger_age && touched.charger_age
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  name="charger_age"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div> */}
                            </>
                          )}
                          <div className={ProductFormCSS.productImages}>
                            <label
                              htmlFor="images"
                              className={ProductFormCSS.label}
                            >
                              Product Image
                            </label>
                            <Row>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image1`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input1Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image1Ref.current) {
                                            image1Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image1`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input1Ref.current.click()}
                                  >
                                    <img ref={image1Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image2 && touched.image2
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image2`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input2Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image2Ref.current) {
                                            image2Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image2`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input2Ref.current.click()}
                                  >
                                    <img ref={image2Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image3 && touched.image3
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image3`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input3Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image3Ref.current) {
                                            image3Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image3`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input3Ref.current.click()}
                                  >
                                    <img ref={image3Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image4`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input4Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image4Ref.current) {
                                            image4Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image4`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input4Ref.current.click()}
                                  >
                                    <img ref={image4Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image5`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input1Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image5Ref.current) {
                                            image5Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image5`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input5Ref.current.click()}
                                  >
                                    <img ref={image5Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className={ProductFormCSS.submitBtn}>
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </Container>
                    </Form>
                  )}
                </Formik>
              </TabPanel>

              {/* -----------------For Old Products ------------------------- */}

              <TabPanel value={1} index={1}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const formData = handleFormData(values);
                    console.log("mainform", Object.fromEntries(formData));

                    const handleSubmit = async () => {
                      const { status_code, res } = await createProduct(
                        formData,
                        token
                      );
                      if (status_code === 200) {
                        navigate(`/${typeOfUser.toLowerCase()}/admin`);
                        setSubmitting(false);
                      } else {
                        toast.error(res);
                      }
                    };

                    handleSubmit();
                  }}
                >
                  {({ errors, isSubmitting, touched, setFieldValue }) => (
                    <Form encType="multipart/form-data">
                      <Container
                        className={ProductFormCSS.mainContainer}
                        style={{ filter: isSubmitting ? "blur(1px)" : "" }}
                      >
                        <div>
                          {isSubmitting && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                        <h2 className={ProductFormCSS.mainHeading}>
                          Add Your Product
                        </h2>
                        <div className={ProductFormCSS.mainForm}>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="name"
                              className={ProductFormCSS.label}
                            >
                              Title
                            </label>{" "}
                            <Field
                              name="name"
                              type="text"
                              className={
                                `form-control ${ProductFormCSS.customField} ` +
                                (errors.name && touched.name
                                  ? "is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="brand"
                              className={ProductFormCSS.label}
                            >
                              Brand
                            </label>
                            <Field
                              as="select"
                              name="brand"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.brand && touched.brand
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="" disabled selected>
                                Choose a brand
                              </option>
                              <option value="Tata">Tata</option>
                              <option value="Ather">Ather</option>
                              <option value="Hyundai">Hyundai</option>
                            </Field>{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="brand"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="model"
                              className={ProductFormCSS.label}
                            >
                              Model
                            </label>{" "}
                            <Field
                              name="model"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.model && touched.model
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="model"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="country"
                              className={ProductFormCSS.label}
                            >
                              Country
                            </label>{" "}
                            <Field
                              name="country"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.country && touched.country
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="country"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="state"
                              className={ProductFormCSS.label}
                            >
                              State
                            </label>{" "}
                            <Field
                              name="state"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.state && touched.state
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="state"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="city"
                              className={ProductFormCSS.label}
                            >
                              City
                            </label>{" "}
                            <Field
                              name="city"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.city && touched.city
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="pincode"
                              className={ProductFormCSS.label}
                            >
                              Pincode
                            </label>{" "}
                            <Field
                              name="pincode"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.pincode && touched.pincode
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="pincode"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="price"
                              className={ProductFormCSS.label}
                            >
                              Price
                            </label>{" "}
                            <Field
                              name="price"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.price && touched.price
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="price"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="number_of_owners"
                              className={ProductFormCSS.label}
                            >
                              Number Of Owners
                            </label>{" "}
                            <Field
                              name="number_of_owners"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.number_of_owners &&
                                touched.number_of_owners
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="number_of_owners"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="registration_number"
                              className={ProductFormCSS.label}
                            >
                              Vehicle Number
                            </label>{" "}
                            <Field
                              name="registration_number"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.registration_number &&
                                touched.registration_number
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="registration_number"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="insurance_expiry"
                              className={ProductFormCSS.label}
                            >
                              Insurance Expiry
                            </label>{" "}
                            <Field
                              name="insurance_expiry"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.insurance_expiry &&
                                touched.insurance_expiry
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="insurance_expiry"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="registered_at"
                              className={ProductFormCSS.label}
                            >
                              Date of Registration
                            </label>{" "}
                            <Field
                              name="registered_at"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.registered_at && touched.registered_at
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="registered_at"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="kms_driven"
                              className={ProductFormCSS.label}
                            >
                              Kms driven
                            </label>
                            <Field
                              name="kms_driven"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.kms_driven && touched.kms_driven
                                  ? " is-invalid"
                                  : "")
                              }
                            />{" "}
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="kms_driven"
                              component="div"
                              className="invalid-feedback"
                            />{" "}
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="description"
                              className={ProductFormCSS.label}
                            >
                              Description
                            </label>{" "}
                            <Field
                              name="description"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.description && touched.description
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="description"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="motor_change_date"
                              className={ProductFormCSS.label}
                            >
                              Motor Age
                            </label>{" "}
                            <Field
                              name="motor_change_date"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.motor_change_date &&
                                touched.motor_change_date
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="motor_change_date"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="battery_change_date"
                              className={ProductFormCSS.label}
                            >
                              Battery Age
                            </label>
                            <Field
                              name="battery_change_date"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.battery_change_date &&
                                touched.battery_change_date
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="battery_change_date"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {/* <div className={ProductFormCSS.formGroup}>
                            <label
                              htmlFor="charger_age"
                              className={ProductFormCSS.label}
                            >
                              Charger Age
                            </label>
                            <Field
                              name="charger_age"
                              type="text"
                              style={{ fontFamily: "myThirdFont" }}
                              className={
                                `form-control  ${ProductFormCSS.customField}` +
                                (errors.charger_age && touched.charger_age
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              name="charger_age"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div> */}
                          <div className={ProductFormCSS.productImages}>
                            <label
                              htmlFor="images"
                              className={ProductFormCSS.label}
                            >
                              Product Image
                            </label>
                            <Row>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image1`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input1Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image1Ref.current) {
                                            image1Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image1`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input1Ref.current.click()}
                                  >
                                    <img ref={image1Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image2 && touched.image2
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image2`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input2Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image2Ref.current) {
                                            image2Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image2`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input2Ref.current.click()}
                                  >
                                    <img ref={image2Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image3 && touched.image3
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image3`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input3Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image3Ref.current) {
                                            image3Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image3`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input3Ref.current.click()}
                                  >
                                    <img ref={image3Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image4`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input4Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image4Ref.current) {
                                            image4Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image4`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input4Ref.current.click()}
                                  >
                                    <img ref={image4Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className={ProductFormCSS.imageBox}>
                                  <Field
                                    className={`form-control ${
                                      ProductFormCSS.imageInput
                                    } ${
                                      errors.image1 && touched.image1
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`image5`}
                                    type="file"
                                    value={undefined}
                                    accept="image/*"
                                    ref={input1Ref}
                                    onChange={(event) => {
                                      const file = event.target.files[0]; // Get the selected file
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                          if (image5Ref.current) {
                                            image5Ref.current.src =
                                              event.target.result;
                                          }
                                          // Update Formik state using setFieldValue
                                        };
                                        reader.readAsDataURL(file);
                                        // setImages([...images, file]);
                                      }
                                      setFieldValue(
                                        event.target.name,
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "120px",
                                    }}
                                    name={`image5`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <div
                                    className={ProductFormCSS.imageBoxDetails}
                                    onClick={() => input5Ref.current.click()}
                                  >
                                    <img ref={image5Ref} />
                                    <span class="material-symbols-outlined">
                                      photo_camera
                                    </span>
                                    <p>Add Photo</p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className={ProductFormCSS.submitBtn}>
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </Container>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
            </Box>
          </Box>
        </div>
      </Container>
    </>
  );
};

export default ProductForm;
