import React from "react";
import { Box, Modal, TextareaAutosize } from "@mui/material";
import ContactUsStyle from "./ContactUsModalStyle.module.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { submitContactUs } from "../../api/Api";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
};

const ContactUsModal = ({ openContatUs, handleCloseConatctUs }) => {
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phoneNumber: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    message: Yup.string().required("Required"),
  });

  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={openContatUs}
        onClose={handleCloseConatctUs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const handleSubmit = async () => {
                const { status_code, res } = await submitContactUs(values);
                if (status_code === 200) {
                  setSubmitting(false);
                  handleCloseConatctUs();
                } else {
                  toast.error(res);
                }
              };
              handleSubmit();
            }}
          >
            {({ errors, isSubmitting, touched, setFieldValue }) => (
              <Form encType="multipart/form-data">
                <ToastContainer />
                <Container
                  className={ContactUsStyle.mainContainer}
                  style={{ filter: isSubmitting ? "blur(1px)" : "" }}
                >
                  <div>
                    {isSubmitting && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                  <h2 className={ContactUsStyle.mainHeading}>Contact US</h2>
                  <div className={ContactUsStyle.mainForm}>
                    <div className={ContactUsStyle.formGroup}>
                      <label htmlFor="name" className={ContactUsStyle.label}>
                        Name
                      </label>{" "}
                      <Field
                        name="name"
                        type="text"
                        className={
                          `form-control ${ContactUsStyle.customField} ` +
                          (errors.name && touched.name ? "is-invalid" : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>
                    <div className={ContactUsStyle.formGroup}>
                      <label htmlFor="email" className={ContactUsStyle.label}>
                        Email{" "}
                        <span style={{ marginLeft: "4px", color: "#7A7A7A" }}>
                          (Official Email)
                        </span>
                      </label>{" "}
                      <Field
                        name="email"
                        type="text"
                        style={{ fontFamily: "myThirdFont" }}
                        className={
                          `form-control  ${ContactUsStyle.customField}` +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>

                    <div className={ContactUsStyle.formGroup}>
                      <label
                        htmlFor="phoneNumber"
                        className={ContactUsStyle.label}
                      >
                        Phone
                      </label>{" "}
                      <Field
                        name="phoneNumber"
                        type="number"
                        style={{ fontFamily: "myThirdFont" }}
                        className={
                          `form-control  ${ContactUsStyle.customField}` +
                          (errors.phoneNumber && touched.phoneNumber
                            ? " is-invalid"
                            : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>
                    <div className={ContactUsStyle.formGroup}>
                      <label htmlFor="message" className={ContactUsStyle.label}>
                        Your Message
                      </label>{" "}
                      <Field
                        name="message"
                        type="text"
                        style={{ fontFamily: "myThirdFont" }}
                        className={
                          `form-control  ${ContactUsStyle.customField}` +
                          (errors.message && touched.message
                            ? " is-invalid"
                            : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        name="message"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>
                    <div className={ContactUsStyle.submitBtn}>
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default ContactUsModal;
