import axios from "axios";

//const BASE_URL = "https://console.bhamo.in/api/v1";
const BASE_URL = "https://bhamo.in/api/v1";
const options = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const signUpUser = async (signUpForm) => {
  let res;
  let status_code;
  try {
    const { phone_number } = signUpForm;

    signUpForm.phone_number = "91" + phone_number;
    const body = {
      ...signUpForm,
    };
    res = await axios.post(`${BASE_URL}/auth`, body, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

// when user signs up as seller after logging in
export const updateUser = async (userId, token, signUpForm) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;

    const body = {
      ...signUpForm,
    };
    res = await axios.put(`${BASE_URL}/auth/update/${userId}`, body, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const submitOtp = async (otp) => {
  let res;
  let status_code;
  const otp_number = Number(otp);
  try {
    const body = {
      otp: otp_number,
    };
    res = await axios.post(`${BASE_URL}/auth/otp`, body, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getAllProducts = async (search = "") => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/product?keyword=${search}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getProductById = async (id) => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/product/${id}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const createProduct = async (details, token = "") => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    options.headers["Content-Type"] = "multipart/form-data";

    res = await axios.post(`${BASE_URL}/product/`, details, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getAdminProducts = async (token = "") => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;

    res = await axios.get(
      // `${BASE_URL}/product/my-product/all?sortbyrange=${range}&sortbyprice=${price}`,
      `${BASE_URL}/product/my-product/all`,
      options
    );
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const createManager = async (receiverEmail, token = "") => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    const body = {
      receiverEmail,
    };
    res = await axios.post(`${BASE_URL}/auth/user/send-mail`, body, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getAllUsers = async () => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/getUsers`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const deleteUser = async (userId, token) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    res = await axios.delete(`${BASE_URL}/auth/delete/${userId}`, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const deleteProduct = async (prodId, token) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    res = await axios.delete(`${BASE_URL}/product/delete/${prodId}`, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const addCompany = async (companyDetails, token) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    options.headers["Content-Type"] = "multipart/form-data";

    res = await axios.post(`${BASE_URL}/company`, companyDetails, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getProductByCompanyId = async (id) => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/company/product_list/${id}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};
export const getCompanyById = async (id) => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/company/${id}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getAllCompany = async (limit) => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/company/brand/details?limit=${limit}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getAllSellers = async () => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/company`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const deleteCompany = async (companyId, token) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    res = await axios.delete(
      `${BASE_URL}/company/delete/${companyId}`,
      options
    );
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const addToWishList = async (id, token) => {
  let res;
  let status_code;
  try {
    options.headers.Authorization = `Bearer ${token}`;
    res = await axios.post(
      `${BASE_URL}/product/wishlist/add/${id}`,
      {},
      options
    );
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getWishList = async (id) => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/product/wishlist/${id}`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    console.log("error", error);
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const getContactUS = async () => {
  let res;
  let status_code;
  try {
    res = await axios.get(`${BASE_URL}/enquiry`);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};

export const submitContactUs = async (values) => {
  let res;
  let status_code;
  try {
    res = await axios.post(`${BASE_URL}/enquiry`, values, options);
    status_code = res?.status;
    res = res?.data;
  } catch (error) {
    res = error?.response?.data?.message || "server error";
    status_code = error?.response?.status || 500;
    return { status_code, res };
  }
  return { status_code, res };
};
