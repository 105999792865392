export const data = [
  {
    id: 1,
    image:
      "https://images.jdmagicbox.com/quickquotes/images_main/auto-rikshaw-hood-compact-bs6-black-2216836769-z4ee6puj.png",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "80000",
    warranty: "2 Years or 60000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 2,
    image:
      "https://5.imimg.com/data5/SELLER/Default/2022/9/UP/RK/JZ/47547323/thunder-electric-scooter.jpg",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "70000",
    warranty: "3 Years or 80000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 3,
    image:
      "https://kineticgreenvehicles.com/images/category/super-dx-thumb-new.png",
    name: "Electrical Rikshaw",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "50000",
    warranty: "1 Year or 90000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 4,
    image:
      "https://mahindralastmilemobility.com/assets/front/images/products/product-slides/treo/silder/treo2.jpg",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "44000",
    warranty: "3 Years or 70000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 5,
    image:
      "https://5.imimg.com/data5/SELLER/Default/2022/9/ED/RE/MN/47547323/electric-scooter-manufacturers.JPG",
    name: "Electrical Rikshaw",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "28000",
    warranty: "2 Years or 50000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 6,
    image:
      "https://a.storyblok.com/f/78437/1920x1080/d347e995d0/bmw-i3-final.jpg",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "33400",
    warranty: "1 Year or 30000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 7,
    image:
      "https://5.imimg.com/data5/SELLER/Default/2023/3/292176029/CS/JC/AE/150329092/yatri-electric-rickshaw.jpg",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "26000",
    warranty: "3 Years or 90000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 8,
    image:
      "https://mahindralastmilemobility.com/assets/front/images/products/product-slides/treo/silder/treo2.jpg",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "25000",
    warranty: "2 Years or 40000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 9,
    image:
      "https://images.jdmagicbox.com/quickquotes/images_main/auto-rikshaw-hood-compact-bs6-black-2216836769-z4ee6puj.png",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "26000",
    warranty: "1 Year or 60000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
  {
    id: 10,
    image:
      "https://kineticgreenvehicles.com/images/category/super-dx-thumb-new.png",
    name: "Tata Ace EV",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "34000",
    warranty: "3 Years or 70000 km",
    range: "120km",
    capacity: "17kwh",
    power: "36hp",
  },
];

export const details = [
  {
    id: 10,
    image: [
      "https://mahindralastmilemobility.com/assets/front/images/products/product-slides/treo/silder/treo2.jpg",

      "https://5.imimg.com/data5/SELLER/Default/2022/9/UP/RK/JZ/47547323/thunder-electric-scooter.jpg",
      "https://images.jdmagicbox.com/quickquotes/images_main/auto-rikshaw-hood-compact-bs6-black-2216836769-z4ee6puj.png",
      "https://kineticgreenvehicles.com/images/category/super-dx-thumb-new.png",
    ],
    name: "Electrical Rikshaw",
    company: "Massive Mobility",
    place: "gurugram, delhi",
    price: "34000",
    material: "plastic",
    Battery: "3.3kWh",
    seat: 2,
    description:
      "E-rickshaws are typically equipped with lead-acid or lithium-ion batteries. Lead-acid batteries are more affordable, but they tend to be heavier and have a shorter lifespan compared to lithium-ion batteries, which are lighter and have a longer cycle life. ",
  },
];

export const typeOfSeller = [
  {
    id: 1,
    title: "OEM",
    discription:
      "An Original Equipment Manufacturer or OEM is a company that manufactures and sells products.",
  },
  {
    id: 2,
    title: "Finance",
    discription:
      "If you are looking to avail finance for the purchase of an electric vehicle.",
  },
  {
    id: 3,
    title: "Dealer",
    discription: "Top EV dealer online.",
  },
  {
    id: 4,
    title: "Individual",
    discription: "Sell Your EV here.",
  },
];

export const signUpOptions = [
  {
    title: "OEM",
    value: "oem",
  },
  {
    title: "FINANCE",
    value: "FINANCE",
  },
  {
    title: "DEALER",
    value: "DEALER",
  },
  {
    title: "INDIVIDUAL",
    value: "INDIVIDUAL",
  },
];
