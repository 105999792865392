// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminTopBar_mainDiv__TEZ-W {
  margin: 32px 0px;
}
.AdminTopBar_innerDiv__kYQRA {
  background-color: #fff;
  padding: 8px;
}

@media screen and (max-width: 575px) {
  .AdminTopBar_selectStyle__exINy {
    margin-top: 12px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Admin/AdminTopBar.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".mainDiv {\n  margin: 32px 0px;\n}\n.innerDiv {\n  background-color: #fff;\n  padding: 8px;\n}\n\n@media screen and (max-width: 575px) {\n  .selectStyle {\n    margin-top: 12px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": `AdminTopBar_mainDiv__TEZ-W`,
	"innerDiv": `AdminTopBar_innerDiv__kYQRA`,
	"selectStyle": `AdminTopBar_selectStyle__exINy`
};
export default ___CSS_LOADER_EXPORT___;
