import { Box, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import MuiButton from "../MuiButton";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
};

const VerificationModal = ({ open, onClose, message }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ width: "100%" }}>
            <Box textAlign={"center"}>
              <img width="100%" src="/images/thankyou.png" alt="" />
            </Box>
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
              }}
            >
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <Typography>
                  {message
                    ? message
                    : "Thank You for signing up, we will reach you soon after verifying your details."}
                </Typography>
              </Box>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                marginTop: "18px",
              }}
            >
              <span>
                <MuiButton
                  onClick={() => {
                    if (!message) {
                      navigate("/");
                      window.location.reload();
                    } else {
                      onClose();
                    }
                  }}
                  label={"OKAY"}
                  bgcolor={"#09753a"}
                  color={"#fff"}
                  width="100%"
                  hoverBg={"#09753a"}
                />
              </span>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VerificationModal;
