import React from "react";
import * as Yup from "yup";
import MainFormCSS from "./MainForm.module.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Container, NavItem } from "react-bootstrap";
import { useParams, useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { addCompany, signUpUser, updateUser } from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import { useSession } from "../../context/authProvider";

const MainForm = ({ setOpenOtpModal }) => {
  const brands = [
    { value: "tata", label: "TATA" },
    { value: "ather", label: "ATHER" },
    { value: "mahindra", label: "MAHINDRA" },
    { value: "piaggio", label: "PIAGGIO" },
    { value: "euler", label: "EULER" },
    { value: "atul auto", label: "ATUL" },
    { value: "lohia", label: "LOHIA" },
    { value: "kinetic green", label: "KINETIC GREEN" },
    { value: "bajaj", label: "BAJAJ" },
    { value: "ampere", label: "AMPERE" },
    { value: "tvs", label: "TVS" },
    { value: "revolt", label: "REVOLT" },
    { value: "bounce infinity", label: "BOUNCE INFINITY" },
    { value: "ola", label: "OLA" },
  ];
  const params = useParams();

  const { token, userDetails } = useSession();
  const navigate = useNavigate();
  let typeOfUser = params.seller;

  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    owner: "",
    company_logo: "",
    owner_pan_front: null || "",
    owner_pan_back: null || "",
    brand_name: "",
    company_pan_front: null || "",
    company_pan_back: null || "",
    gst_num: "",
    about: "",
    logo: "",
    website: "",
    customer_care: "",
    arai_icat: null || "",
  };

  const handleFormData = (values) => {
    console.log("dfdf", values);
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("phone", values?.phone);
    formData.append("owner", values?.owner);
    formData.append("owner_pan", values?.owner_pan);
    formData.append("brand_name", values?.brand_name);
    formData.append("company_pan", values?.company_pan);
    formData.append("gst_num", values?.gst_num);
    formData.append("website", values?.website);
    formData.append("customer_care", values?.customer_care);
    formData.append("arai_icat", values?.arai_icat);

    return formData;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    phone_number: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    owner:
      typeOfUser !== "individual" &&
      typeOfUser !== "dealer" &&
      Yup.string().required("Required"),

    company_logo:
      typeOfUser !== "individual" &&
      typeOfUser !== "dealer" &&
      Yup.mixed().required("Required"),
    owner_pan_front: Yup.mixed().required("Required"),
    owner_pan_back: Yup.mixed().required("Required"),
    brand_name: typeOfUser === "oem" && Yup.string().required("Required"),
    company_pan_front:
      typeOfUser !== "individual" &&
      typeOfUser !== "dealer" &&
      Yup.mixed().required("Required"),
    company_pan_back:
      typeOfUser !== "individual" &&
      typeOfUser !== "dealer" &&
      Yup.mixed().required("Required"),
    gst_num: typeOfUser !== "individual" && Yup.string().required("Required"),
    about: typeOfUser !== "individual" && Yup.string().required("Required"),
    logo: Yup.string(),
    website: Yup.string(),
    customer_care: typeOfUser === "oem" && Yup.number().required("Required"),
    arai_icat: typeOfUser === "oem" && Yup.mixed().required("Required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const updatedValues = values;

          if (typeOfUser.toLowerCase() === "oem") {
            updatedValues.isOem = "true";
          } else if (typeOfUser.toLowerCase() === "finance") {
            updatedValues.isFinance = "true";
          } else if (typeOfUser.toLowerCase() === "dealer") {
            updatedValues.isDealer = "true";
          } else if (typeOfUser.toLowerCase() === "individual") {
            updatedValues.isIndividualSeller = "true";
          }
          updatedValues.isVerified = 2;
          updatedValues.company_name = values.brand_name || values.phone_number;

          let sellerExists = true;
          if (
            !userDetails?.isOem &&
            !userDetails?.isFinance &&
            !userDetails?.isDealer &&
            !userDetails?.isIndividualSeller
          ) {
            sellerExists = false;
          }

          const handleSubmit = async () => {
            let statusCode, response;
            if (token && userDetails?._id && !sellerExists) {
              updatedValues.phone_number = userDetails?.phone_number;
              const { status_code, res } = await updateUser(
                userDetails?._id,
                token,
                updatedValues
              );
              statusCode = status_code;
              response = res;

              if (statusCode === 200) {
                const { status_code, res } = await addCompany(
                  updatedValues,
                  token
                );
                if (status_code === 200) {
                  navigate("/");
                } else {
                  toast.error(res);
                }
                // setOpenOtpModal(true);
              } else {
                toast.error(response);
              }
            } else {
              setSubmitting(false);
              toast.error("Seller already exists");
            }
          };

          handleSubmit();
        }}
      >
        {({ errors, isSubmitting, touched, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <ToastContainer />
            <Container
              className={MainFormCSS.mainContainer}
              style={{ filter: isSubmitting ? "blur(1px)" : "" }}
            >
              <div>
                {isSubmitting && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
              <h2 className={MainFormCSS.mainHeading}>
                SIGN UP AS {typeOfUser.toUpperCase()}
              </h2>
              <div className={MainFormCSS.mainForm}>
                <div className={MainFormCSS.formGroup}>
                  <label htmlFor="name" className={MainFormCSS.label}>
                    Name
                  </label>{" "}
                  <Field
                    name="name"
                    type="text"
                    className={
                      `form-control ${MainFormCSS.customField} ` +
                      (errors.name && touched.name ? "is-invalid" : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>
                <div className={MainFormCSS.formGroup}>
                  <label htmlFor="email" className={MainFormCSS.label}>
                    Email{" "}
                    <span style={{ marginLeft: "4px", color: "#7A7A7A" }}>
                      (Official Email)
                    </span>
                  </label>{" "}
                  <Field
                    name="email"
                    type="text"
                    style={{ fontFamily: "myThirdFont" }}
                    className={
                      `form-control  ${MainFormCSS.customField}` +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>

                <div className={MainFormCSS.formGroup}>
                  <label htmlFor="phone" className={MainFormCSS.label}>
                    Phone
                  </label>{" "}
                  <Field
                    name="phone_number"
                    type="number"
                    style={{ fontFamily: "myThirdFont" }}
                    className={
                      `form-control  ${MainFormCSS.customField}` +
                      (errors.phone_number && touched.phone_number
                        ? " is-invalid"
                        : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="phone_number"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>
                {typeOfUser !== "dealer" && typeOfUser !== "individual" && (
                  <>
                    <div className={MainFormCSS.formGroup}>
                      <label htmlFor="owner" className={MainFormCSS.label}>
                        Owner
                      </label>{" "}
                      <Field
                        name="owner"
                        type="text"
                        style={{ fontFamily: "myThirdFont" }}
                        className={
                          `form-control  ${MainFormCSS.customField}` +
                          (errors.owner && touched.owner ? " is-invalid" : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="owner"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>

                    <div className={MainFormCSS.formGroup}>
                      <label
                        htmlFor="company_logo"
                        className={MainFormCSS.label}
                      >
                        Company Logo
                      </label>{" "}
                      <Field
                        name="company_logo"
                        type="file"
                        accept="image/*"
                        value={undefined}
                        style={{ fontFamily: "myThirdFont" }}
                        onChange={(event) => {
                          const file = event.target.files[0]; // Get the selected file
                          if (file) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                          }
                          setFieldValue(
                            event.target.name,
                            event.currentTarget.files[0]
                          );
                        }}
                        className={
                          `form-control  ${MainFormCSS.customField}` +
                          (errors.company_logo && touched.company_logo
                            ? " is-invalid"
                            : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="company_logo"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>

                    <div className={MainFormCSS.formGroup}>
                      <label
                        htmlFor="company_pan_front"
                        className={MainFormCSS.label}
                      >
                        Company PAN(front side)
                      </label>{" "}
                      <Field
                        name="company_pan_front"
                        type="file"
                        accept="image/*"
                        value={undefined}
                        style={{ fontFamily: "myThirdFont" }}
                        onChange={(event) => {
                          const file = event.target.files[0]; // Get the selected file
                          if (file) {
                            const reader = new FileReader();
                            // reader.onload = (event) => {
                            //   if (image1Ref.current) {
                            //     image1Ref.current.src =
                            //       event.target.result;
                            //   }
                            //   // Update Formik state using setFieldValue
                            // };
                            reader.readAsDataURL(file);
                            // setImages([...images, file]);
                          }
                          setFieldValue(
                            event.target.name,
                            event.currentTarget.files[0]
                          );
                        }}
                        className={
                          `form-control  ${MainFormCSS.customField}` +
                          (errors.company_pan_front && touched.company_pan_front
                            ? " is-invalid"
                            : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="company_pan_front"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>
                    <div className={MainFormCSS.formGroup}>
                      <label
                        htmlFor="company_pan_back"
                        className={MainFormCSS.label}
                      >
                        Company PAN(back side)
                      </label>{" "}
                      <Field
                        name="company_pan_back"
                        type="file"
                        accept="image/*"
                        value={undefined}
                        onChange={(event) => {
                          const file = event.target.files[0]; // Get the selected file
                          if (file) {
                            const reader = new FileReader();
                            // reader.onload = (event) => {
                            //   if (image1Ref.current) {
                            //     image1Ref.current.src =
                            //       event.target.result;
                            //   }
                            //   // Update Formik state using setFieldValue
                            // };
                            reader.readAsDataURL(file);
                            // setImages([...images, file]);
                          }
                          setFieldValue(
                            event.target.name,
                            event.currentTarget.files[0]
                          );
                        }}
                        style={{ fontFamily: "myThirdFont" }}
                        className={
                          `form-control  ${MainFormCSS.customField}` +
                          (errors.company_pan_back && touched.company_pan_back
                            ? " is-invalid"
                            : "")
                        }
                      />{" "}
                      <ErrorMessage
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        name="company_pan_back"
                        component="div"
                        className="invalid-feedback"
                      />{" "}
                    </div>
                  </>
                )}
                <div className={MainFormCSS.formGroup}>
                  <label
                    htmlFor="owner_pan_front"
                    className={MainFormCSS.label}
                  >
                    Owner PAN(front side)
                  </label>{" "}
                  <Field
                    name="owner_pan_front"
                    type="file"
                    accept="image/*"
                    value={undefined}
                    onChange={(event) => {
                      const file = event.target.files[0]; // Get the selected file
                      if (file) {
                        const reader = new FileReader();
                        // reader.onload = (event) => {
                        //   if (image1Ref.current) {
                        //     image1Ref.current.src =
                        //       event.target.result;
                        //   }
                        //   // Update Formik state using setFieldValue
                        // };
                        reader.readAsDataURL(file);
                        // setImages([...images, file]);
                      }
                      setFieldValue(
                        event.target.name,
                        event.currentTarget.files[0]
                      );
                    }}
                    style={{ fontFamily: "myThirdFont" }}
                    className={
                      `form-control  ${MainFormCSS.customField}` +
                      (errors.owner_pan_front && touched.owner_pan_front
                        ? " is-invalid"
                        : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="owner_pan_front"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>
                <div className={MainFormCSS.formGroup}>
                  <label htmlFor="owner_pan_back" className={MainFormCSS.label}>
                    Owner PAN(back side)
                  </label>{" "}
                  <Field
                    name="owner_pan_back"
                    type="file"
                    accept="image/*"
                    value={undefined}
                    onChange={(event) => {
                      const file = event.target.files[0]; // Get the selected file
                      if (file) {
                        const reader = new FileReader();
                        // reader.onload = (event) => {
                        //   if (image1Ref.current) {
                        //     image1Ref.current.src =
                        //       event.target.result;
                        //   }
                        //   // Update Formik state using setFieldValue
                        // };
                        reader.readAsDataURL(file);
                        // setImages([...images, file]);
                      }
                      setFieldValue(
                        event.target.name,
                        event.currentTarget.files[0]
                      );
                    }}
                    style={{ fontFamily: "myThirdFont" }}
                    className={
                      `form-control  ${MainFormCSS.customField}` +
                      (errors.owner_pan_back && touched.owner_pan_back
                        ? " is-invalid"
                        : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="owner_pan_back"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>

                {typeOfUser === "oem" && (
                  <div className={MainFormCSS.formGroup}>
                    <label htmlFor="brand_name" className={MainFormCSS.label}>
                      Brand Name
                    </label>{" "}
                    <Field
                      as="select"
                      name="brand_name"
                      type="text"
                      style={{ fontFamily: "myThirdFont" }}
                      className={
                        `form-control  ${MainFormCSS.customField}` +
                        (errors.brand_name && touched.brand_name
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <option value="" disabled selected>
                        Choose a brand
                      </option>
                      {brands.map((brand) => (
                        <option value={brand.value}>{brand.label}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      name="brand_name"
                      component="div"
                      className="invalid-feedback"
                    />{" "}
                  </div>
                )}

                {typeOfUser !== "individual" && (
                  <div className={MainFormCSS.formGroup}>
                    <label htmlFor="gst_num" className={MainFormCSS.label}>
                      GST Number
                    </label>{" "}
                    <Field
                      name="gst_num"
                      type="text"
                      style={{ fontFamily: "myThirdFont" }}
                      className={
                        `form-control  ${MainFormCSS.customField}` +
                        (errors.gst_num && touched.gst_num ? " is-invalid" : "")
                      }
                    />{" "}
                    <ErrorMessage
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      name="gst_num"
                      component="div"
                      className="invalid-feedback"
                    />{" "}
                  </div>
                )}
                {typeOfUser !== "individual" && (
                  <div className={MainFormCSS.formGroup}>
                    <label htmlFor="about" className={MainFormCSS.label}>
                      About
                    </label>{" "}
                    <Field
                      name="about"
                      type="text"
                      style={{ fontFamily: "myThirdFont" }}
                      className={
                        `form-control  ${MainFormCSS.customField}` +
                        (errors.about && touched.about ? " is-invalid" : "")
                      }
                    />{" "}
                    <ErrorMessage
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      name="about"
                      component="div"
                      className="invalid-feedback"
                    />{" "}
                  </div>
                )}
                <div className={MainFormCSS.formGroup}>
                  <label htmlFor="website" className={MainFormCSS.label}>
                    Website{" "}
                    <span style={{ marginLeft: "4px", color: "#7A7A7A" }}>
                      (Optional)
                    </span>
                  </label>{" "}
                  <Field
                    name="website"
                    type="text"
                    style={{ fontFamily: "myThirdFont" }}
                    className={
                      `form-control  ${MainFormCSS.customField}` +
                      (errors.website && touched.website ? " is-invalid" : "")
                    }
                  />{" "}
                  <ErrorMessage
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    name="website"
                    component="div"
                    className="invalid-feedback"
                  />{" "}
                </div>
                {typeOfUser === "oem" && (
                  <div className={MainFormCSS.formGroup}>
                    <label
                      htmlFor="customer_care"
                      className={MainFormCSS.label}
                    >
                      Customer Care
                    </label>{" "}
                    <Field
                      name="customer_care"
                      type="text"
                      style={{ fontFamily: "myThirdFont" }}
                      className={
                        `form-control  ${MainFormCSS.customField}` +
                        (errors.customer_care && touched.customer_care
                          ? " is-invalid"
                          : "")
                      }
                    />{" "}
                    <ErrorMessage
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      name="customer_care"
                      component="div"
                      className="invalid-feedback"
                    />{" "}
                  </div>
                )}
                {typeOfUser === "oem" && (
                  <div className={MainFormCSS.formGroup}>
                    <label htmlFor="arai_icat" className={MainFormCSS.label}>
                      ARAI ICAT
                    </label>{" "}
                    <Field
                      name="arai_icat"
                      type="file"
                      accept="application/pdf"
                      value={undefined}
                      onChange={(event) => {
                        const file = event.target.files[0]; // Get the selected file
                        if (file) {
                          const reader = new FileReader();
                          // reader.onload = (event) => {
                          //   if (image1Ref.current) {
                          //     image1Ref.current.src =
                          //       event.target.result;
                          //   }
                          //   // Update Formik state using setFieldValue
                          // };
                          reader.readAsDataURL(file);
                          // setImages([...images, file]);
                        }
                        setFieldValue(
                          event.target.name,
                          event.currentTarget.files[0]
                        );
                      }}
                      style={{ fontFamily: "myThirdFont" }}
                      className={
                        `form-control  ${MainFormCSS.customField}` +
                        (errors.arai_icat && touched.arai_icat
                          ? " is-invalid"
                          : "")
                      }
                    />{" "}
                    <ErrorMessage
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      name="arai_icat"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                )}
                <div className={MainFormCSS.submitBtn}>
                  <button type="submit">Submit</button>
                </div>
              </div>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MainForm;
