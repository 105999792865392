import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const searchContext = createContext();

const SearchProvider = ({ children }) => {
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [clickSearched, setClickSearched] = useState(false);

  return (
    <searchContext.Provider
      value={{
        search,
        setSearch,
        setSearchedProducts,
        setSearchLoader,
        setClickSearched,
        searchLoader,
        searchedProducts,
        clickSearched,
      }}
    >
      {children}
    </searchContext.Provider>
  );
};
export const useSearchProducts = () => {
  return useContext(searchContext);
};

export default SearchProvider;
