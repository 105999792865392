// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Cards - Section - Div */
.ProductDetails_cardsDiv__q2rfx {
  margin: 64px 0px;
  background-color: #fff;
  padding: 12px;
}
.ProductDetails_cardsDiv__q2rfx h3 {
  display: flex;
  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);
  font-weight: 600;
  font-family: mySecondFont;
  color: #2b2b2b;
}

/* ProductTabs */
.ProductDetails_productTabs__rm6vp {
  margin: 42px 0px;
}
.ProductDetails_tabpanel__Q\\+-JS {
  background-color: #fff !important;
}
.ProductDetails_companyCol__BhdQJ {
  background-color: #fff;
}
.ProductDetails_productTabBar__VPmsU {
  background-color: #fff !important;
}
.ProductDetails_tabbtn__yCcBw {
  width: 50% !important;
}

/* Products - Tabs - Inner - Data */
.ProductDetails_proDetailsSection__Ucb-H {
  padding: 16px;
}
.ProductDetails_detailsAllCategory__nFx1C {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ProductDetails_detailsCategory__O8SRT {
  display: flex;
  align-items: baseline;
  /* max-width: 400px; */
  width: 100%;
  justify-content: space-between;
}
.ProductDetails_detailsCategory__O8SRT h3 {
  font-size: clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem);
  font-family: myThirdFont;
}
.ProductDetails_detailsCategory__O8SRT p {
  font-size: 16px;
  font-family: myThirdFont;
  text-align: start;
}
.ProductDetails_descriptiondata__OO\\+aW {
  max-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Product/ProductDetails.module.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,aAAa;EACb,qDAAqD;EACrD,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;AAChB;;AAEA,gBAAgB;AAChB;EACE,gBAAgB;AAClB;AACA;EACE,iCAAiC;AACnC;AACA;EACE,sBAAsB;AACxB;AACA;EACE,iCAAiC;AACnC;AACA;EACE,qBAAqB;AACvB;;AAEA,mCAAmC;AACnC;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,sDAAsD;EACtD,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/* Cards - Section - Div */\n.cardsDiv {\n  margin: 64px 0px;\n  background-color: #fff;\n  padding: 12px;\n}\n.cardsDiv h3 {\n  display: flex;\n  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);\n  font-weight: 600;\n  font-family: mySecondFont;\n  color: #2b2b2b;\n}\n\n/* ProductTabs */\n.productTabs {\n  margin: 42px 0px;\n}\n.tabpanel {\n  background-color: #fff !important;\n}\n.companyCol {\n  background-color: #fff;\n}\n.productTabBar {\n  background-color: #fff !important;\n}\n.tabbtn {\n  width: 50% !important;\n}\n\n/* Products - Tabs - Inner - Data */\n.proDetailsSection {\n  padding: 16px;\n}\n.detailsAllCategory {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.detailsCategory {\n  display: flex;\n  align-items: baseline;\n  /* max-width: 400px; */\n  width: 100%;\n  justify-content: space-between;\n}\n.detailsCategory h3 {\n  font-size: clamp(1rem, 0.9741rem + 0.1258vw, 1.125rem);\n  font-family: myThirdFont;\n}\n.detailsCategory p {\n  font-size: 16px;\n  font-family: myThirdFont;\n  text-align: start;\n}\n.descriptiondata {\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsDiv": `ProductDetails_cardsDiv__q2rfx`,
	"productTabs": `ProductDetails_productTabs__rm6vp`,
	"tabpanel": `ProductDetails_tabpanel__Q+-JS`,
	"companyCol": `ProductDetails_companyCol__BhdQJ`,
	"productTabBar": `ProductDetails_productTabBar__VPmsU`,
	"tabbtn": `ProductDetails_tabbtn__yCcBw`,
	"proDetailsSection": `ProductDetails_proDetailsSection__Ucb-H`,
	"detailsAllCategory": `ProductDetails_detailsAllCategory__nFx1C`,
	"detailsCategory": `ProductDetails_detailsCategory__O8SRT`,
	"descriptiondata": `ProductDetails_descriptiondata__OO+aW`
};
export default ___CSS_LOADER_EXPORT___;
