// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_active__7SppK {
  color: green;
}
.Home_inActive__6BlnN {
  color: black;
}

.Home_mainHeading__U1nqd {
  color: #484848 !important;
}

/* Cards - Section - Div */
.Home_cardsDiv__uxi3A {
  margin: 64px 0px;
  background-color: #fff;
  padding: 12px;
}

/* Brand - Section - Div */
.Home_brandDiv__Iha3W {
  margin: 64px 0px;
}
.Home_brandDiv__Iha3W p,
.Home_cardsDiv__uxi3A h3 {
  display: flex;
  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);
  font-weight: 600;
  font-family: mySecondFont;
  color: #2b2b2b;
}
.Home_brandCard__VXwXm {
  background-color: #fff;
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 144px;
}
.Home_brandCardDiv__U6Gy3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0px;
}
.Home_colGap__nRAG0 {
  margin-bottom: 16px;
}

/* Media - query */
@media screen and (max-width: 992px) {
  .Home_brandRow__gGK52 {
    display: flex;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,0BAA0B;AAC1B;EACE,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;AACf;;AAEA,0BAA0B;AAC1B;EACE,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,qDAAqD;EACrD,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA,kBAAkB;AAClB;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".active {\n  color: green;\n}\n.inActive {\n  color: black;\n}\n\n.mainHeading {\n  color: #484848 !important;\n}\n\n/* Cards - Section - Div */\n.cardsDiv {\n  margin: 64px 0px;\n  background-color: #fff;\n  padding: 12px;\n}\n\n/* Brand - Section - Div */\n.brandDiv {\n  margin: 64px 0px;\n}\n.brandDiv p,\n.cardsDiv h3 {\n  display: flex;\n  font-size: clamp(1.25rem, 1.0943rem + 0.7547vw, 2rem);\n  font-weight: 600;\n  font-family: mySecondFont;\n  color: #2b2b2b;\n}\n.brandCard {\n  background-color: #fff;\n  /* border-radius: 8px; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 144px;\n}\n.brandCardDiv {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin: 24px 0px;\n}\n.colGap {\n  margin-bottom: 16px;\n}\n\n/* Media - query */\n@media screen and (max-width: 992px) {\n  .brandRow {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `Home_active__7SppK`,
	"inActive": `Home_inActive__6BlnN`,
	"mainHeading": `Home_mainHeading__U1nqd`,
	"cardsDiv": `Home_cardsDiv__uxi3A`,
	"brandDiv": `Home_brandDiv__Iha3W`,
	"brandCard": `Home_brandCard__VXwXm`,
	"brandCardDiv": `Home_brandCardDiv__U6Gy3`,
	"colGap": `Home_colGap__nRAG0`,
	"brandRow": `Home_brandRow__gGK52`
};
export default ___CSS_LOADER_EXPORT___;
