import React from "react";
import Header from "../../Header/Header";
import GoogleTranslate from "../../../GoogleTranslate/GoogleTranslate";
import Test from "../../../GoogleTranslate/Test";

const ProfilePage = () => {
  return (
    <>
      <Header />
      <p>User Profile</p>

      <GoogleTranslate />
      {/* <Test /> */}
    </>
  );
};

export default ProfilePage;
